import { useState, useEffect, useRef, useContext } from "react";
import { useNavigate, useSearchParams, useParams, Link } from "react-router-dom";

import ProgressBar from '../Components/ProgressBar';
import AssessmentQuestionContext from '../Store/AssessmentQuestionContext';
import '../App.scss';
import useWindowDimensions from '../Hooks/useWindowDimensions';
import { Button } from "@mui/material";
import prevArrow from '../Assets/Images/prev-arrow.svg';


const Halfway = props => {
  
  const { id }                            = useParams();
  let navigate                            = useNavigate();
  const { windowWidth }                   = useWindowDimensions();
  const [isLoading, setIsLoading]         = useState(true);
  const [searchParams, setSearchParams]   = useSearchParams();
  const assessCtx     = useContext(AssessmentQuestionContext);

  useEffect(() => {
    localStorage.removeItem("backFromHalfway");
    localStorage.removeItem("goToNextSection");
  }, [])

  searchParams.get("leader");

  const assessmentLink = () => {
    localStorage.setItem("goToNextSection", "true");
    if(![...searchParams]?.length) {
      return "/assessment";
    } else {
      return "/assessment?" + searchParams;
    }
  }
  
  const backToAssessmentNextSection = () => {
    localStorage.setItem("goToNextSection", "true");
    if(id) navigate("/assessment/" + id + '/review?' + searchParams, { replace: true });
    else navigate("/assessment?" + searchParams, { replace: true });
  }

  const prevPage = () => {
    // so we can use this to set question number to 14 in assessment 
    localStorage.setItem("backFromHalfway", "true");
    if(id) navigate("/assessment/" + id + '/review?' + searchParams, { replace: true });
    else navigate("/assessment?" + searchParams, { replace: true });
  }


  return (
    <>
    <div className="container assessment halfway">
      <div className="intro steps">
        <ProgressBar currentProgress={13} questionsPerSection={14} sections={assessCtx.sections} count={28}/>
        <h1>Great job! You're halfway done.</h1>
        <p>Now, move from thinking about yourself personally to thinking about the ministry organization you work for. </p>
        <p className="space-above">Answer this next set of questions on behalf of your ministry, thinking broadly about its core strengths and most critical needs.</p>

        <div className="completion">
        <Button className="back-btn" onClick={prevPage}><img src={prevArrow} alt="Previous Page Button" />&nbsp;&nbsp;</Button>
          <Button onClick={() => backToAssessmentNextSection()} className="cta">Continue</Button>
        </div>
      </div>
    </div>
    </>
  );
}
export default Halfway;