import { useState, useEffect, useRef } from "react";
import { Link, useSearchParams } from "react-router-dom";

import VideoThumbnail from '../Assets/Images/Nathan-Artt.jpg';
import PlayBtn from '../Assets/Images/play-button.png';
import Clock from '../Assets/Images/clock.png';
import liquidLogo from "../Assets/Images/liquid-logo.png";
import Logo from '../Assets/Images/logo_EL.png';
import '../App.scss';
import Video from '../Components/Video';
import useWindowDimensions from '../Hooks/useWindowDimensions';

const Start = props => {
  const { windowWidth }                   = useWindowDimensions();

  const [isLoading, setIsLoading]         = useState(true);

  const [searchParams, setSearchParams]   = useSearchParams();
  const [playingVideo, setPlayingVideo]   = useState(false);
  const [startPlaying, setStartPlaying]   = useState(false);
  const vidRef                            = useRef(null);
  
  searchParams.get("leader");


  const howItWorksLink = () => {
    if(searchParams == "") {
      return "/how-it-works";
    } else {
      return "/how-it-works?" + searchParams;
    }
  }

  const playVideo = (e) => {
    console.log(vidRef.current);
    // vidRef.current.play();
    // setPlayingVideo(true);
    // if(!startPlaying) {
    //   setStartPlaying(true);
    // }
    if(windowWidth > 830) {
      // document.getElementsByClassName("intro")[0].style.opacity = "0";
      document.getElementsByClassName("intro")[0].style.width = "0%";
      document.getElementsByClassName("intro")[0].style.padding = "0";
      document.getElementsByClassName("intro")[0].style.overflow = "hidden";
      document.getElementsByClassName("video")[0].style.width = "100%";
      document.getElementsByClassName("video")[0].style.margin = "0 auto";
      document.getElementsByClassName("video")[0].style.backgroundColor = "#22223B";
    }
    vidRef.current.play();
    setPlayingVideo(true);
    if(!startPlaying) {
      setStartPlaying(true);
    }
  }

  const pauseVideo = (e) => {
    console.log(vidRef.current);
    // vidRef.current.pause();
    // setPlayingVideo(false);
    vidRef.current.pause();
    if(windowWidth > 830) {
      document.getElementsByClassName("intro")[0].style.width = "41%";
      document.getElementsByClassName("intro")[0].style.padding = "0 6%";
      document.getElementsByClassName("intro")[0].style.overflow = "hidden";
      // document.getElementsByClassName("intro")[0].style.opacity = "1";
      document.getElementsByClassName("video")[0].style.width = "65%";
      document.getElementsByClassName("video")[0].style.margin = "0 auto";
      document.getElementsByClassName("video")[0].style.backgroundColor = "#22223B";
    }
    setPlayingVideo(false);
  }

  const handleVideoPlaybackEnd = () => {
    setPlayingVideo(false);
    if(windowWidth > 830) {
      document.getElementsByClassName("intro")[0].style.width = "41%";
      document.getElementsByClassName("intro")[0].style.padding = "0 6%";
      document.getElementsByClassName("intro")[0].style.overflow = "hidden";
      // document.getElementsByClassName("intro")[0].style.opacity = "1";
      document.getElementsByClassName("video")[0].style.width = "65%";
      document.getElementsByClassName("video")[0].style.margin = "0 auto";
      document.getElementsByClassName("video")[0].style.backgroundColor = "#22223B";
    }
    showThumbnail();
    showPlayButton();
  }

  const showThumbnail = () => {
    if(playingVideo) {
      return <img className="thumbnail" src={VideoThumbnail} style={{ opacity: '0'}} onClick={(e) => pauseVideo(e)} />;
    } else {
      if(startPlaying) {
        return null
      } else {
        return <img className="thumbnail" src={VideoThumbnail} />
      }
    }
  }

  const showPlayButton = () => {
    if(playingVideo) {
      return null
    } else {
      return <img className="playbtn" src={PlayBtn} onClick={(e) => playVideo(e)} alt="play button icon" />
    }
  }

  // const vidsrcURL = "https://40parables-assets.s3.amazonaws.com/bleat-church-fuel-v2.mp4";
  const vidsrcURL = "https://40parables-assets.s3.amazonaws.com/clear-path-forward.mp4";

  return (
    <>
    <div className="container start">
      {/* <Video vidsrcURL={vidsrcURL} thumbnail={VideoThumbnail} playButton={PlayBtn} /> */}
      {/* <div className="video">
        <video ref={vidRef} onEnded={handleVideoPlaybackEnd}>
          <source
            src={vidsrcURL}
            type="video/mp4"
            />
        </video>
        {showThumbnail()}
        {showPlayButton()}
      </div> */}
      <div className="intro">
        <h1>Unlock your innovation potential!</h1>
        <h2>Find your inner innovator and learn how to grow your strengths to help your ministry meet its biggest needs.</h2>
        {/*<br />
        
        <h2>Which of the 9 opportunities should be your #1 priority?</h2>*/}
        <div className="completion">
          {/* <Link to={assessmentLink()} className="cta">Get Started</Link> */}
          <Link to={howItWorksLink()} className="cta">Get Started</Link>
          <div className='time-estimate'>
            <img className='clock-icon' src={Clock} alt="" /> <span>5 min</span>
          </div>
        </div>
        {/* <div className="completion">Estimated time for completion: 3 min</div> */}
        {/*<div className="logos-section">
          {windowWidth < 831 && <>
            <img className="logo-left" src={Logo} alt="Executive Leadership Solutions Logo" srcset="" />
            <div className="logo-separator"></div>
          </>}

          <img className="logo-right" src={liquidLogo} alt="Liquid Church Logo" srcset="" />
          
        </div>*/}
      </div>
    </div>
    </>
  );
}
export default Start;