import { useState, useEffect, useContext } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { Link, useParams, useNavigate } from "react-router-dom";
import LinearProgress from "@mui/material/LinearProgress";
import CircularProgress from "@mui/material/CircularProgress";
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import * as highchartsMore from "highcharts/highcharts-more";
import bookImg from "../Assets/Images/GreenSky_F.png"
import amazon from "../Assets/Images/amazon.png"
import audible from "../Assets/Images/audible.png"
import lifeway from "../Assets/Images/lifeway.png"
import barnesNoble from "../Assets/Images/barnes&noble.png"
import apple from "../Assets/Images/appleB.png"
import books from "../Assets/Images/books.png"
import moody from "../Assets/Images/moody.png"
import target from "../Assets/Images/target.png"
import ebooks from "../Assets/Images/ebooks.png"


// import Chart from "react-apexcharts";
// import ReactApexChart from "react-apexcharts";

// import prevArrow from "../Assets/Images/prev-arrow.svg";
// import nextArrow from "../Assets/Images/next-arrow.svg";

import testimonialImage from "../Assets/Images/testimonial.jpg";
import quotationImage from "../Assets/Images/quotation.svg";

// import analyzeIcon from '../Assets/Images/insights.svg';
// import alignmentIcon from '../Assets/Images/align.svg';
// import advanceIcon from '../Assets/Images/advance.svg';
import useWindowDimensions from '../Hooks/useWindowDimensions';
import AssessmentQuestionContext from "../Store/AssessmentQuestionContext";
import "../App.scss";

const labelsAndInfo = [
  { id: "KINGDOM BUILDER", 
    def: "Kingdom Builders look for ways to create connections to leverage the strength and diversity within the Body of Christ.",
    support: [
      "You have natural empathy and a gift for building relationships. You value other people and their diverse perspectives, recognizing that we are all members of the Body of Christ. We can do more together than any one person can do alone. As a Kingdom Builder, your greatest strength is your humility and belief that everyone has something important to offer."],
    improve: ["One of the most valuable ways you can serve your ministry is to foster unity in a variety of situations. Leverage your social connectedness to add diversity that might otherwise be lacking. Diversity will level up your ministry, but only if it can flourish in a safe environment. Continue to reach out your hand to others and cultivate community that reflects God's kingdom purposes.", <em>This Innovation Opportunity represents the highest alignment between your personal strengths and your ministry needs. <strong>If you can only read one chapter in the whole book, we recommend you start here.</strong></em>, <a className="book-chapter-link" href="https://greenskyinnovation.com/read-kingdom-builder/" target="_blank" rel="noopener noreferrer">Read the Kingdom Builder's Perspective</a>],
  },
  { id: "PHILOSOPHER", 
    def: "Philosophers uncover the unseen realities that affect innovation and problem-solve underlying causes to current challenges.",
    support: [
      "You have a unique way of seeing the world that allows you to sit comfortably in the abstract and incomplete. You are process-oriented, curious, and love to ask deep questions that lack simple answers. You aren’t afraid to tackle the complex problems that scare others away.  The tenacity you bring to uncovering connections allows you to illuminate the path forward for others."],
    improve: ["Your superpower is your ability to ask questions that highlight hidden causes and their unexpected effects. The gift you offer your ministry is seeing the big picture and analyzing it from multiple viewpoints until you find the connecting thread. You may find yourself frustrated by the recurring problems of systems that need reform, but remember that people are complicated and change takes time.", <em>This Innovation Opportunity represents the highest alignment between your personal strengths and your ministry needs. <strong>If you can only read one chapter in the whole book, we recommend you start here.</strong></em>, <a className="book-chapter-link" href="https://greenskyinnovation.com/read-philosopher/" target="_blank" rel="noopener noreferrer">Read the Philosopher's Perspective</a>],
  },
  { id: "BEGINNER", 
    def: "Beginners look at the world with fresh eyes to discover how to truly serve well.",
    support: [
      "You are a full-immersion learner. You love to hear people's stories and see the world through their eyes. Because of this you have learned to let go of your assumptions, which uniquely frees you to see reality unfiltered. You are adaptable because you know the world never stays the same. Knowledge and skills must be constantly renewed and being a beginner is actually a gift."],
    improve: ["You may find you see things very differently from others on your team. As you constantly absorb diverse perspectives, you are likely to identify new realities and unmet needs long before others do. Plus, you are more willing to let go of old ways and embrace new ones in order to remain relevant. Practice patience as you bring people on the journey with you and teach them how to be beginners too.", <em>This Innovation Opportunity represents the highest alignment between your personal strengths and your ministry needs. <strong>If you can only read one chapter in the whole book, we recommend you start here.</strong></em>, <a className="book-chapter-link" href="https://greenskyinnovation.com/read-beginner/" target="_blank" rel="noopener noreferrer">Read the Beginner's Perspective</a>],
  },
  { id: "STORYTELLER", 
    def: "Storytellers inspire others to join them on the journey by bringing the right message to an audience they understand deeply.",
    support: [
      "You are a gifted communicator who understands that stories are more powerful than facts. You can see both the bigger picture and the details that fill it in. Your passion and wit motivate others, and you are at your best when you are working for a meaningful cause. You are an includer who can convene people around a shared purpose and sustain vision that carries all of you forward."],
    improve: ["Among the many gifts you bring to your ministry is your cultural memory. You are a champion for the vision season after season. But you are in danger of feeling gaps more acutely, especially those that exist between words and actions. Be part of the solution by helping shape the journey you are inviting others to, both inside and outside your ministry.", <em>This Innovation Opportunity represents the highest alignment between your personal strengths and your ministry needs. <strong>If you can only read one chapter in the whole book, we recommend you start here.</strong></em>, <a className="book-chapter-link" href="https://greenskyinnovation.com/read-storyteller/" target="_blank" rel="noopener noreferrer">Read the Storyteller's Perspective</a>],
  },
  { id: "CULTURE MAKER", 
    def: "Culture Makers create an environment where innovation can flourish for kingdom purposes.",
    support: [
      "You are optimistic and see infinite potential in the people and situations around you. You want to make the future better, not for your own sake but for others. You often find yourself at the center of things and people trust you with their experiences and stories. Because of this, you can see what many do not, including what needs to change and how that will affect the people involved."],
    improve: ["You can be a powerful agent for positive change at your ministry. Relational trust is your unique currency and you can use your influence to advocate for people. Work to create environments where everyone can flourish. If people are disengaged, stay in the fight. You can help bring hope. Aim for small wins, celebrate them, and bring people on the journey with you to sustain change long term.", <em>This Innovation Opportunity represents the highest alignment between your personal strengths and your ministry needs. <strong>If you can only read one chapter in the whole book, we recommend you start here.</strong></em>, <a className="book-chapter-link" href="https://greenskyinnovation.com/read-culture-maker/" target="_blank" rel="noopener noreferrer">Read the Culture Maker's Perspective</a>],
  },
  { id: "HOST", 
    def: "Hosts create meaningful experiences and enable people to bring their best to collaboration.",
    support: [
      "You are people oriented in the best way and catch on to details others miss. Your keen mind is always at work figuring out ways to make life easier and overcome challenges. You derive satisfaction from serving others even when it means sacrificing yourself. You know that no job is too small in God's kingdom. We all have a role to play and should work with excellence."],
    improve: ["You are uniquely suited to unlock innovative potential at your ministry by honing a secret skillset: facilitation. As a host, you can facilitate gatherings that bring out the best in any team. Your people skills can build trust and collaboration that propel diverse groups in the right direction. You can also learn to create powerful experiences that break the script and bring needed insight.", <em>This Innovation Opportunity represents the highest alignment between your personal strengths and your ministry needs. <strong>If you can only read one chapter in the whole book, we recommend you start here.</strong></em>, <a className="book-chapter-link" href="https://greenskyinnovation.com/read-host/" target="_blank" rel="noopener noreferrer">Read the Host's Perspective</a>],
  },
  { id: "LEARNER", 
    def: "Learners guide ministry in new directions by testing ideas quickly and gaining knowledge from both successes and failures.",
    support: [
      "Learning is not an activity for you–it’s a lifestyle. You breathe in new knowledge like needed oxygen. You are optimistic about the future and what it will bring. Change is not scary so much as it is exciting. You are fearless when it comes to facing your own blindspots because you are humble enough to know there will always be more to learn. The fun is in what you will find out."],
    improve: ["Your curiosity is an incredible asset to your ministry. Never let it die! Your discoveries will point you to what’s working and what’s not. Continue to learn new skills and absorb diverse perspectives. Infuse the gift of learning into your team by helping them experiment on a small scale. Most critically, help others overcome the shame of mistakes and learn that failure is our best opportunity.", <em>This Innovation Opportunity represents the highest alignment between your personal strengths and your ministry needs. <strong>If you can only read one chapter in the whole book, we recommend you start here.</strong></em>, <a className="book-chapter-link" href="https://greenskyinnovation.com/read-learner/" target="_blank" rel="noopener noreferrer">Read the Learner's Perspective</a>],
  },
];

const Results = (props) => {

  const assessCtx = useContext(AssessmentQuestionContext);
  const { windowWidth }                 = useWindowDimensions();
  let navigate                          = useNavigate();
  const { id }                          = useParams();
  
  const [isLoading, setIsLoading]       = useState(true);
  const [isMember, setIsMember]         = useState(false);
  const [name, setName]                 = useState("");
  const [score, setScore]               = useState(0);
  const [questions, setQuestions]       = useState(assessCtx?.questions);
  // const [subScore1, setSubScore1]       = useState(0);
  // const [subScore2, setSubScore2]       = useState(0);
  // const [subScore3, setSubScore3]       = useState(0);
  // const [subScore4, setSubScore4]       = useState(0);
  // const [subScore5, setSubScore5]       = useState(0);
  // const [subScore6, setSubScore6]       = useState(0);
  // const [grpSubScore1, setGrpSubScore1] = useState(0);
  // const [grpSubScore2, setGrpSubScore2] = useState(0);
  // const [grpSubScore3, setGrpSubScore3] = useState(0);
  // const [grpSubScore4, setGrpSubScore4] = useState(0);
  // const [grpSubScore5, setGrpSubScore5] = useState(0);
  // const [grpSubScore6, setGrpSubScore6] = useState(0);
  const [sortedLabelsAndInfo, setSortedLabelsAndInfo] = useState(labelsAndInfo.sort((a, b) => a.score - b.score));
  const [sameScoreWithBothLabels, setSameScoreWithBothLabels] = useState(false);
  const [apiData, setApiData]           = useState(null);
  const [chartOptions, setChartOptions] = useState(null);
  const [hoveredText, setHoveredText]   = useState(sortedLabelsAndInfo[0].id);
  const [infoText, setInfoText]         = useState(labelsAndInfo.find(label => label.id === hoveredText)?.def);
  const [hoveredScoreObj, setHoveredScoreObj] = useState(labelsAndInfo?.find(label => label.id === hoveredText))
  // const [infoPosition, setInfoPosition] = useState({right: "4%", left: "33.5%"});
  const [infoPosition, setInfoPosition] = useState({});
  const [updateChart, setUpdateChart]   = useState(false);
  
  // use subScores only for bar chart animations 
  // const [subScores, setSubScores] = useState([subScore1, subScore2, subScore3, subScore4, subScore5, subScore6]);
  // const [grpSubScores, setGrpSubScores] = useState([grpSubScore1, grpSubScore2, grpSubScore3, grpSubScore4, grpSubScore5, grpSubScore6]);
  // const [chartSubScores, setChartSubScores] = useState([]);
  // const [chartGrpSubScores, setChartGrpSubScores] = useState([]);
  // const [barChartSubScores, setBarChartSubScores] = useState([]);
  // const [barChartGrpSubScores, setBarChartGrpSubScores] = useState([]);

  // console.log(subScores, grpSubScores);
  // const hoveredScoreObj = labelsAndInfo?.find(label => label.id === hoveredText);
  
  let chartElm = document?.getElementById("spiderweb-chart1");
  // console.log(chartElm);
  
  // set hoveredText to lowest score's text after fetching scores from API 
  let lowestScore = sortedLabelsAndInfo[0].score;
  let highestScore = sortedLabelsAndInfo[sortedLabelsAndInfo?.length-1]?.score;
  // console.log("highestScore", highestScore);
  // useEffect(() => {
  //     setHoveredText(sortedLabelsAndInfo[0]?.id);
  // }, [sortedLabelsAndInfo, lowestScore, highestScore, hoveredScoreObj])

  useEffect(() => {
    if(labelsAndInfo[0]?.score) setUpdateChart(!updateChart);
    else setUpdateChart(!updateChart);
  }, [labelsAndInfo[0]?.score])
  
  useEffect(() => {
    const checkHover = (e) => {
      // console.log(e.target.innerHTML);
      if(e.target.classList.contains("custom-tooltip-text")) {
        console.log(e.target.innerHTML);
        let text = e.target.innerHTML;
        let finalText = e.target.innerHTML;
        switch (text) {
          case 'KINGDOM<br/>BUILDER': finalText = "KINGDOM BUILDER";
            break;
          case 'KINGDOM<br>BUILDER': finalText = "KINGDOM BUILDER";
            break;
          case 'PHILOS-<br/>OPHER': finalText = "PHILOSOPHER";
            break;
          case 'PHILOS-<br>OPHER': finalText = "PHILOSOPHER";
            break;
          case 'BEGIN-<br/>NER': finalText = "BEGINNER";
            break;
          case 'BEGIN-<br>NER': finalText = "BEGINNER";
            break;
          case 'CULTURE<br/>MAKER': finalText = "CULTURE MAKER";
            break;
          case 'CULTURE<br>MAKER': finalText = "CULTURE MAKER";
            break;
          default: finalText = text;
        }
        // setHoveredText(e.target?.innerHTML)
        setHoveredText(finalText);
        // console.log("infoText", infoText);
        // console.log("hoveredText", hoveredText);
      }
    }
    
    if(chartElm) {
      chartElm.addEventListener('mouseover', checkHover, false);
    }

    if(chartElm) {
      return () => {
        chartElm.removeEventListener('mouseover', checkHover);
      }
    }

  }, [chartElm, hoveredText]);

  useEffect(() => {
    setInfoText(labelsAndInfo.find((item) => item?.id === hoveredText)?.def);
  }, [hoveredText, chartElm])
  
  useEffect(() => {
    if (windowWidth >= 1100) {
      setInfoPositionStyle(labelsAndInfo.find((item) => item?.id === hoveredText)?.id);
    }
    if(windowWidth < 1180) setInfoPosition({right: "calc(50% - 177.5px)", top: "99.5%"})
  }, [hoveredText, chartElm, chartOptions, windowWidth])


  // console.log("infoText", infoText);
  // console.log("hoveredText", hoveredText);

  highchartsMore(Highcharts);

  const sortedAccToScores = [...labelsAndInfo]?.sort((a, b) => {
    return a.score > b.score ? -1 : a.score < b.score ? 1 : 0;
  })
  // console.log("sortedAccToScores", sortedAccToScores);

  
  const setInfoPositionStyle = (section) => {
    // console.log("section", section);
    // let doesntHaveLabels = false;
    let hasBothLabels    = sameScoreWithBothLabels;
    let doesntHaveLabels = section !== sortedAccToScores?.[0]?.id && section !== closestHighScores?.id;
    // console.log("section", section, sortedAccToScores?.[0]?.id, closestHighScores?.id);
    // console.log("doesntHaveLabels", doesntHaveLabels);
    // let hasBottomLabel   = section === closestHighScores?.id;
    // let hasTopLabel      = section === sortedAccToScores?.[0]?.id;
    // console.log("\ndoesntHaveLabels", doesntHaveLabels, section);
    // top style % are = doesnt have labels ? has both labels ? has one label 
    if(section === "BEGINNER") {
      setInfoPosition({ right: "3%", top: doesntHaveLabels ? "63%" : hasBothLabels ? "65.5%" : "63.5%" });
    } else if(section === "STORYTELLER") {
      setInfoPosition({ right: "13.5%", top: doesntHaveLabels ? "90.5%" : hasBothLabels ? "96.5%" : "93.5%" });
    } else if(section === "CULTURE MAKER") {
      setInfoPosition({ right: "55.5%", top: doesntHaveLabels ? "90.5%" : hasBothLabels ? "96.5%" : "93.5%" });
    } else if(section === "PHILOSOPHER") {
      setInfoPosition({ right: "6%", top: doesntHaveLabels ? "0.5%" : hasBothLabels ? "-3.5%" : "-3.5%" });
    } else if(section === "HOST") {
      setInfoPosition({ right: "66%", top: doesntHaveLabels ? "63%" : hasBothLabels ? "65.5%" : "63.5%" });
    } else if(section === "LEARNER") {
      setInfoPosition({ right: "62.5%", top: doesntHaveLabels ? "0.5%" : hasBothLabels ? "-3.5%" : "-3.5%" });
    } else if(section === "KINGDOM BUILDER") {
      // if score highest/lowest, show banner higher to make space for highest/lowest score label
      // if(sortedLabelsAndInfo[0]?.id === section || sortedLabelsAndInfo[sortedLabelsAndInfo?.length - 1]?.id === section) {
      setInfoPosition({ right: "34%", top: doesntHaveLabels ? "-15%" : hasBothLabels ? "-22%" : "-18%" });
    } else {
      setInfoPosition({ right: "36.5%", top: "98.5%"});
    }
  }
  
  const fetchResults = () => {
    fetch(process.env.REACT_APP_API_URI + "/v1/assessment/" + id, {
      method: "GET",
      mode: "cors",
      headers: { "Content-Type": "application/json" },
    })
    .then((response) => {
      return response.json();
    })
    .then((data) => {
      if (data.status === "success") {
        console.log(data);
        setIsLoading(false);
        setApiData(data);
        setName(data.name);
        setScore(data.score);
        // setSubScore1(data.subScore1);
        // setSubScore2(data.subScore2);
        // setSubScore3(data.subScore3);
        // setSubScore4(data.subScore4);
        // setSubScore5(data.subScore5);
        // setSubScore6(data.subScore6);
        // setGrpSubScore1(data.groupSubScore1)
        // setGrpSubScore2(data.groupSubScore2)
        // setGrpSubScore3(data.groupSubScore3)
        // setGrpSubScore4(data.groupSubScore4)
        // setGrpSubScore5(data.groupSubScore5)
        // setGrpSubScore6(data.groupSubScore6)
        // setSubScores([data.subScore1, data.subScore2, data.subScore3, data.subScore4, data.subScore5, data.subScore6]);
        // setGrpSubScores([data.groupSubScore1, data.groupSubScore2, data.groupSubScore3, data.groupSubScore4, data.groupSubScore5, data.groupSubScore6]);
        setIsMember(data.isTeamMember);
      } else {
        // setOpenSnackbar(true);
      }
    });
  };

  function getQuestions() {
    
    let clientId              = process.env.REACT_APP_CLIENT_ID;
    fetch(process.env.REACT_APP_API_URI + '/v1/questions/' + clientId, {
      method: 'GET',
      mode: 'cors',
      headers: { 'Content-Type': 'application/json' }
    }).then(response => {
      return response.json();
    }).then(data => {
      if(data.status == "success") {
        console.log("\n\nFetching Questions from API", data);
        setQuestions(data.questions);
        assessCtx.addQuestions(data.questions);
        setIsLoading(false);
      } else {
        // setOpenSnackbar(true);
      }
    });
  }

  const filterArrays = (questionsArr, questionsIdArr) => {
    let questionAnswersObj = { questions: [], average: 0 };
    questionsArr?.forEach(q => {
      if(questionsIdArr.includes(q.id)) {
        let answerArr = apiData?.answers?.find(ans => ans.question_id === q.id)
        let answer = q.type === "slider" ? answerArr?.answer : q.type === "radio" ? answerArr?.answer * 1.667 : answerArr?.answer;
        if(answer) {
          questionAnswersObj.questions.push({ questionId: q.id, question: q.question, type: q.type, answer: answer });
        }
      }
    })
    let totalScore = 0;
    questionAnswersObj?.questions?.forEach(qa => totalScore += parseFloat(qa.answer?.toFixed(2)))
    questionAnswersObj.average = parseFloat((totalScore / questionAnswersObj?.questions?.length).toFixed(2));
    // console.log("questionAnswersObj", questionAnswersObj);
    return questionAnswersObj;
  }

  const kingdomBuilderQsIdArr1 = [ 386, 378, 388, 381, 385, 380 ]; // myself section scores 
  const kingdomBuilderQsIdArr2 = [ 394, 395 ]; // ministry section scores 
  const philosopherQsIdArr1    = [ 387, 382, 389, 384, 391, 383 ];
  const philosopherQsIdArr2    = [ 397, 398 ];
  const beginnerQsIdArr1       = [ 386, 378, 390, 379, 388, 381 ];
  const beginnerQsIdArr2       = [ 392, 396 ];
  const storyTellerQsIdArr1    = [ 386, 378, 387, 382, 385, 380 ];
  const storyTellerQsIdArr2    = [ 401, 393 ];
  const cultureMakerQsIdArr1   = [ 390, 379, 391, 383, 385, 380 ];
  const cultureMakerQsIdArr2   = [ 402, 403 ];
  const hostQsIdArr1           = [ 386, 378, 389, 384, 385, 380 ];
  const hostQsIdArr2           = [ 399, 400 ];
  const learnerQsIsArr1        = [ 390, 379, 388, 381, 391, 383 ];
  const learnerQsIsArr2        = [ 404, 405 ];

  const kingdomBuilder1  = filterArrays(questions, kingdomBuilderQsIdArr1);
  const kingdomBuilder2  = filterArrays(questions, kingdomBuilderQsIdArr2);
  const philosopher1     = filterArrays(questions, philosopherQsIdArr1);
  const philosopher2     = filterArrays(questions, philosopherQsIdArr2);
  const beginner1        = filterArrays(questions, beginnerQsIdArr1);
  const beginner2        = filterArrays(questions, beginnerQsIdArr2);
  const storyTeller1     = filterArrays(questions, storyTellerQsIdArr1);
  const storyTeller2     = filterArrays(questions, storyTellerQsIdArr2);
  const cultureMaker1    = filterArrays(questions, cultureMakerQsIdArr1);
  const cultureMaker2    = filterArrays(questions, cultureMakerQsIdArr2);
  const host1            = filterArrays(questions, hostQsIdArr1);
  const host2            = filterArrays(questions, hostQsIdArr2);
  const learner1         = filterArrays(questions, learnerQsIsArr1);
  const learner2         = filterArrays(questions, learnerQsIsArr2);

  // console.log("questions", questions); console.log("apiData answers", apiData?.answers);
  // console.log("kingdomBuilder1", kingdomBuilder1); console.log("kingdomBuilder2", kingdomBuilder2);
  // console.log("philosopher1", philosopher1); console.log("philosopher2", philosopher2);
  // console.log("beginner1", beginner1); console.log("beginner2", beginner2);
  // console.log("storyTeller1", storyTeller1); console.log("storyTeller2", storyTeller2);
  // console.log("cultureMaker1", cultureMaker1); console.log("cultureMaker2", cultureMaker2);
  // console.log("host1", host1); console.log("host2", host2);
  // console.log("learner1", learner1); console.log("learner2", learner2);
  
  // this is to show Innovation Opportunity on Spiderweb Chart
  // sorted scores array where score and avgScore are closest to each other (least difference) 
  // and are also higher, if not highest among all scores 
  // so calculate average of both scores and then subtract the difference between both scores from the average and sort 
  // const highestClosestSortedScores = [...labelsAndInfo]?.sort((a, b) => {
  //   return (b.avgOfBothScores - b.difference) < (a.avgOfBothScores - a.difference) ? -1 : (b.avgOfBothScores - b.difference) > (a.avgOfBothScores - a.difference) ? 1 : 0;
  // })
  
  // UPDATE TO SCORING CALCULATIONS for Innovation Opportunity 
  // changed calculation to make Innovation Opportunity the score with max total of score (personal strength) 
  // and avgScore (ministry need) which is scoreTotal = score + avgScore and then sorted highest to lowest 
  // it is no longer the closest of highest scores, just the highest score but with the SAME VARIABLE NAME 
  const highestClosestSortedScores = [...labelsAndInfo]?.sort((a, b) => {
    return (b.scoreTotal < a.scoreTotal) ? -1 : (b.scoreTotal > a.scoreTotal) ? 1 : 0;
  })

  console.log("labelsAndInfo", labelsAndInfo);
  // console.log("highestClosestSortedScores", highestClosestSortedScores);

  const closestHighScores = highestClosestSortedScores?.[0];
  // console.log("\n\nhighestClosestSortedScores", highestClosestSortedScores);
  // console.log("closestHighScores", closestHighScores);
  // console.log("hoveredText", hoveredText);
  // console.log("hoveredScoreObj?.id", hoveredScoreObj?.id, "\n\n");

  useEffect(() => {
    setHoveredText(closestHighScores?.id)
  }, [closestHighScores, closestHighScores?.id])

  useEffect(() => {
    setHoveredScoreObj(labelsAndInfo?.find(label => label.id === hoveredText));
  }, [hoveredText])

  useEffect(() => {
    if(!score && !name) fetchResults();
    if (!assessCtx?.questions?.length) {
      getQuestions();
    }
  }, [assessCtx, assessCtx?.questions, assessCtx?.questions?.length]);

  useEffect(() => {
    const matchLabelWithScore = (item) => {
      
      if(item.id === "KINGDOM BUILDER"){
        return [kingdomBuilder1?.average, kingdomBuilder2?.average];
      } else if (item.id === "PHILOSOPHER") {
        return [philosopher1.average, philosopher2.average];
      } else if (item.id === "BEGINNER") {
        return [beginner1.average, beginner2.average];
      } else if (item.id === "STORYTELLER") {
        return [storyTeller1.average, storyTeller2.average];
      } else if (item.id === "CULTURE MAKER") {
        return [cultureMaker1.average, cultureMaker2.average];
      } else if (item.id === "HOST") {
        return [host1.average, host2.average];
      } else if (item.id === "LEARNER") {
        return [learner1.average, learner2.average];
      }
    }
    labelsAndInfo?.forEach(label => {
      const labelScore0 = matchLabelWithScore(label)?.[0];
      const labelScore1 = matchLabelWithScore(label)?.[1];
      label.score = labelScore0;
      label.avgScore = labelScore1;
      label.scoreTotal = parseFloat((labelScore0 + labelScore1).toFixed(2));
      label.difference = parseFloat(Math.abs(labelScore0 - labelScore1).toFixed(2));
      label.avgOfBothScores = parseFloat(((labelScore0 + labelScore1) / 2).toFixed(2));
    });

  // }, [grpSubScore1, grpSubScore2, grpSubScore3, grpSubScore4, grpSubScore5, grpSubScore6, subScore1, subScore2, subScore3, subScore4, subScore5, subScore6]);
  }, [beginner1.average, beginner2.average, cultureMaker1.average, cultureMaker2.average, host1.average, host2.average, kingdomBuilder1.average, kingdomBuilder2.average, learner1.average, learner2.average, philosopher1.average, philosopher2.average, storyTeller1.average, storyTeller2.average, questions, questions?.length]);
  
  // useEffect(() => {
  //   // change negative scores between -30 and +30 to positive scores between 0 and 60 for chart by adding 30 to each score. so new min and max will be 0 and 60 
  //   setChartSubScores(subScores?.map(score => (score + 50)));
  //   setChartGrpSubScores(grpSubScores?.map(score => (score + 50)));
    
  //   setBarChartSubScores(subScores?.map((score) => Math.round(((score + 50) / 100) * 100)));
  //   setBarChartGrpSubScores(grpSubScores?.map(score => Math.round(((score + 50) / 100) * 100)));
    
  // }, [subScores, grpSubScores]);

  useEffect(() => {
    let chartData = {
      chart: {
        // plotBackgroundColor: '#5d557d',
        // backgroundColor: '#5d557d',
        // marginBottom: 100,
        plotShadow: false,
        polar: true,
        type: 'area',
        // height: 450,
        height: 500,
        // width: 750,
        // spacing: [10, 10, 15, 10], // default spacing. increase this to have more space around chart on mobile 
        spacing: [20, 20, 20, 20],
        tickInterval: 1,
        startOnTick: true,
        endOnTick: false,
        // margin: 'auto',
        style: {
          fontFamily: "Lato",
        },
      },
      // colors: ['#927AA0', '#45A5CF', '#8bbc21', '#2f7ed8', '#492970', '#f28f43', '#77a1e5', '#c42525', '#a6c96a'],
      colors: ['#009CD777', '#00A04F77', '#8bbc21', '#2f7ed8', '#492970', '#f28f43', '#77a1e5', '#c42525', '#a6c96a'],
   
      credits: {
        enabled: false,
      },
      
      accessibility: {
          description: 'A spiderweb chart compares your scores against the average scores. The spider chart has seven spokes. Each spoke represents one of the 7 points: KINGDOM BUILDER, PHILOSOPHER, BEGINNER, STORYTELLER, CULTURE MAKER, and HOST. The chart is interactive, and each data point is displayed upon hovering.'
      },
      
      title: {
          text: '',
          x: -80
      },
      
      pane: {
          size: '80%'
      },
      
      xAxis: {
        categories: [
          'KINGDOM BUILDER', 
          'PHILOSOPHER',
          'BEGINNER', 
          'STORYTELLER', 
          'CULTURE MAKER', 
          'HOST',
          'LEARNER',
        ],
        tickmarkPlacement: 'on',
        tickInterval: 1,
        startOnTick: true,
        endOnTick: false,
        tickColor: 'transparent',
        lineWidth: 0,
        labels: {
          enabled: true,
          useHTML: true,
          formatter: function() {
            // console.log(this.value);
            // text = (text.length < 6) ? this.value : this.value.substring(0, 6) + '-'
            let text = this.value;
            let finalText = this.value;
            if(windowWidth < 651) {
              switch (text) {
                case 'KINGDOM BUILDER':
                  finalText = "KINGDOM<br/>BUILDER";
                  break;
                  // return '<div align="center" style="min-width: 170px">' + this.value + '</div>';
                  // return '<div align="center" style="min-width: 100px; position: relative; left: 0px; bottom: 12px; text-align: center;">KINGDOM BUILDER</div>';
                case 'PHILOSOPHER':
                  finalText = "PHILOS-<br/>OPHER";
                  break;
                case 'BEGINNER':
                  finalText = "BEGIN-<br/>NER";
                  break;
                  // return '<div align="center" style="min-width: 100px; position: relative; left: -32px; top: 4px; text-align: center;">BEGIN<br/>NER</div>'
                case 'STORYTELLER':
                  finalText = text;
                  break;
                case 'CULTURE MAKER':
                  // finalText = "CULTURE<br>MAKER";
                  finalText = text;
                  break;
                  // return '<div align="center" style="min-width: 160px; position: relative; left: -12px; bottom: 0px;">' + this.value + '</div>';
                case 'HOST':
                  finalText = text;
                  break;
                default:
                  finalText = text;
              }
          //     return (finalText)
          //   } else {
          //     switch (text) {
          //       case 'KINGDOM BUILDER':
          //         // return '<div align="center" style="min-width: 160px; position: relative; left: -70px; bottom: -8px;">' + this.value + '</div>';
          //         return '<div align="center" style="min-width: 160px; position: relative; left: -12px; bottom: 0px;">' + this.value + '</div>';
          //       case 'CULTURE MAKER':
          //         return '<div align="center" style="min-width: 150px; position: relative; bottom: 0px;">' + this.value + '</div>';
          //       default: return finalText;
          //     }
          //     // return (finalText)
          //   }
          // },
          return (
            '<div class="custom-tooltip"><span class="highest-score">Personal<br/>Strength</span><span class="lowest-score">Innovation<br/>Opportunity</span><p class="custom-tooltip-text">' + finalText + '</p></div>')
        } else return (
        '<div class="custom-tooltip"><span class="highest-score">Personal Strength</span><span class="lowest-score">Innovation Opportunity</span><p class="custom-tooltip-text">' + finalText + '</p></div>')
      },
          style: {
            fontSize: "16px",
            fontWeight: 700,
            color: "black",
          },
        },
        gridLineColor: '#9d9d9d9d',
      },
            
      yAxis: {
        // categories: ['', '', '', '', ''],
        tickInterval: 1,
        startOnTick: true,
        endOnTick: true,
        gridLineInterpolation: 'polygon',
        lineWidth: 0,
        min: 0,
        gridLineColor: '#9d9d9d9d',
        max: 10,
        tickColor: 'transparent',
      },
      plotOptions: {
        area: {
          // fillColor: '#927AA0',
          // fillOpacity: 0.9,
          opacity: 0.9,
        },    
      },
      
      tooltip: {
        enabled: true,
        padding: 0,
        distance: 0,
        animation: false,
        // shared: true,
        // pointFormat: '<span style="color:{series.color}">{series.name}: <b>{point.y:,.0f}</b><br/>'
        useHTML: true,
        formatter: function() {
          // console.log("x: ", this.x, "y: ", this.y);
          setHoveredText(this.x);
          return '<span style="display: none; color: transparent; background: transparent;" class="hidden-tooltips">'+ this.x +'</span>';
        }
      },
      
      legend: {
        enabled: false,
        // layout: 'horizontal',
        // floating: true,
        // align: 'center',
        // verticalAlign: 'bottom',
      },
      
      series: [
        {
          name: 'Ministry Need',
          data: [
            // these scores are out of 5 so multiply by 2 to make them up to 10 which is max here 
            Math.round(kingdomBuilder2?.average * 2),
            Math.round(philosopher2?.average * 2),
            Math.round(beginner2?.average * 2),
            Math.round(storyTeller2?.average * 2),
            Math.round(cultureMaker2?.average * 2),
            Math.round(host2?.average * 2),
            Math.round(learner2?.average * 2),
          ],
          pointPlacement: 'on'
        },
        {
          name: 'Personal Strength',
          data: [
            // these scores are out of 5 so multiply by 2 to make them up to 10 which is max here 
            Math.round(kingdomBuilder1?.average * 2),
            Math.round(philosopher1?.average * 2),
            Math.round(beginner1?.average * 2),
            Math.round(storyTeller1?.average * 2),
            Math.round(cultureMaker1?.average * 2),
            Math.round(host1?.average * 2),
            Math.round(learner1?.average * 2),
          ],
          pointPlacement: 'on'
        }
      ],
      
      responsive: {
          rules: [{
              condition: {
                  maxWidth: 500
              },
              chartOptions: {
                  legend: {
                      align: 'center',
                      verticalAlign: 'bottom',
                      layout: 'horizontal'
                  },
                  pane: {
                      size: '70%'
                  }
              }
          }]
        }
    };
    setChartOptions(chartData);
  // }, [chartSubScores, chartGrpSubScores, windowWidth]);
  }, [windowWidth, beginner1?.average, beginner2?.average, cultureMaker1?.average, cultureMaker2?.average, host1?.average, host2?.average, kingdomBuilder1?.average, kingdomBuilder2?.average, learner1?.average, learner2?.average, philosopher1?.average, philosopher2?.average, storyTeller1?.average, storyTeller2?.average, closestHighScores, closestHighScores?.avgOfBothScores, updateChart]);

  // const sortedLabelsAndInfo = labelsAndInfo.sort((a, b) => a.score - b.score);
  // console.log("sortedLabelsAndInfo", sortedLabelsAndInfo);

  // console.log("sameScoreWithBothLabels", sameScoreWithBothLabels);
  // console.log("sortedAccToScores?.[0]?.id", sortedAccToScores?.[0]?.id);
  // console.log("closestHighScores?.id", closestHighScores?.id);
  useEffect(() => {
    let sameCategoryWithBothTitles = sortedAccToScores?.[0]?.id === closestHighScores?.id ? true : false;
    if (sameCategoryWithBothTitles) {
      setSameScoreWithBothLabels(true);
    } else setSameScoreWithBothLabels(false);
    // setSameScoreWithBothLabels(true);
  }, [sortedAccToScores, closestHighScores, closestHighScores?.id])

  useEffect(() => {
    // setHoveredText(closestHighScores?.id);
    let labelsArr = document.querySelectorAll(".custom-tooltip");
    console.log("sameScoreWithBothLabels", sameScoreWithBothLabels);
    // console.log(labelsArr);
    for (let i = 0; i < labelsArr.length; i++) {
      // check lowest score and show that label 

      let text = labelsArr[i].getElementsByClassName("custom-tooltip-text")[0].innerHTML;
        let finalText = labelsArr[i].getElementsByClassName("custom-tooltip-text")[0].innerHTML;
        switch (text) {
          case 'KINGDOM<br>BUILDER':
            finalText = "KINGDOM BUILDER";
            break;
          case 'KINGDOM<br/>BUILDER':
            finalText = "KINGDOM BUILDER";
            break;
          case 'PHILOS-<br>OPHER':
            finalText = "PHILOSOPHER";
            break;
          case 'PHILOS-<br/>OPHER':
            finalText = "PHILOSOPHER";
            break;
          case 'BEGIN-<br>NER':
            finalText = "BEGINNER";
            break;
          case 'BEGIN-<br/>NER':
            finalText = "BEGINNER";
            break;
          case 'CULTURE<br>MAKER':
            finalText = "CULTURE MAKER";
            break;
          case 'CULTURE<br/>MAKER':
            finalText = "CULTURE MAKER";
            break;
          default:
            finalText = text;
        }

      const lowestText = "Innovation<br/>Opportunity";
      const highestText = "Personal<br/>Strength";

      let lowest = labelsArr[i].getElementsByClassName("lowest-score")[0];
      let highest = labelsArr[i].getElementsByClassName("highest-score")[0];

      // check lowest score and show that label 
      if(finalText === closestHighScores?.id) {
        labelsArr[i].getElementsByClassName("lowest-score")[0].classList.add('show');
        if(sameScoreWithBothLabels) labelsArr[i].getElementsByClassName("lowest-score")[0].classList.add('has-both-labels');
      } else {
        labelsArr[i].getElementsByClassName("lowest-score")[0].classList.add('hide');
      }
      
      // check highest score and show that label 
      // if(finalText === sortedLabelsAndInfo[sortedLabelsAndInfo.length-1]?.id) {
      if(finalText === sortedAccToScores[0]?.id) {
        labelsArr[i].getElementsByClassName("highest-score")[0].classList.add('show');
        if(sameScoreWithBothLabels) labelsArr[i].getElementsByClassName("highest-score")[0].classList.add('has-both-labels');
      } else {
        labelsArr[i].getElementsByClassName("highest-score")[0].classList.add('hide');
      }

      if(finalText === "KINGDOM BUILDER") {
        lowest.style.bottom = sameScoreWithBothLabels ? "38px" : "20px";
      } if(finalText === "PHILOSOPHER") {
        lowest.style.bottom = sameScoreWithBothLabels ? "-18px" : "20px";
      } if(finalText === "BEGINNER") {
        lowest.style.bottom = sameScoreWithBothLabels ? "-18px" : "20px";
      }
      // for STORYTELLER & CULTURE MAKER which are at the bottom of chart. To prevent label from overlapping on chart 
      if(finalText === "STORYTELLER" || finalText === "CULTURE MAKER") {
        lowest.style.bottom = sameScoreWithBothLabels ? "-35px" : "-18px";
        highest.style.bottom = sameScoreWithBothLabels ? "-18px" : "-18px";
      }
      if(finalText === "HOST") {
        lowest.style.left = "-13ch";
        lowest.style.bottom = sameScoreWithBothLabels ? "-18px" : "20px";
        highest.style.left = "-9ch";
      }
      if(finalText === "LEARNER") {
        lowest.style.left = "-11ch";
        lowest.style.bottom = sameScoreWithBothLabels ? "-18px" : "20px";
        highest.style.left = "-6ch";
      }
      if(windowWidth < 651) {
        let lowest = labelsArr[i].getElementsByClassName("lowest-score")[0];
        let highest = labelsArr[i].getElementsByClassName("highest-score")[0];
        lowest.innerHTML = lowestText;
        highest.innerHTML = highestText;
        if(finalText === "KINGDOM BUILDER") {
          lowest.innerHTML = "Innovation Opportunity";
          highest.innerHTML = "Personal Strength";
          lowest?.classList.add("first-text"); 
          highest?.classList.add("first-text");
          labelsArr[i].getElementsByClassName("custom-tooltip-text")[0].style.position = "relative";
          labelsArr[i].getElementsByClassName("custom-tooltip-text")[0].style.bottom = "10px";
          lowest.style.bottom = sameScoreWithBothLabels ? "42px" : "42px";
        }
        if(finalText === "PHILOSOPHER") {
          lowest?.classList.add("second-text");
          highest?.classList.add("second-text");
          lowest.style.bottom = sameScoreWithBothLabels ? "34px" : "34px";
          highest.style.bottom = sameScoreWithBothLabels ? "-26px" : "-26px";
        }
        if(finalText === "BEGINNER") {
          lowest?.classList.add("third-text");
          highest?.classList.add("third-text");
          lowest.style.bottom = sameScoreWithBothLabels ? "-28px" : "-28px";
          highest.style.bottom = sameScoreWithBothLabels ? "38px" : "38px";
        }
        if(finalText === "STORYTELLER") {
          lowest?.classList.add("fourth-text"); 
          highest?.classList.add("fourth-text");
          lowest.innerHTML = "Innovation Opportunity";
          highest.innerHTML = "Personal Strength";
          lowest.style.bottom = sameScoreWithBothLabels ? "-25px" : "-12px";
        }
        if(finalText === "CULTURE MAKER") {
          lowest?.classList.add("fifth-text"); 
          highest?.classList.add("fifth-text");
          lowest.innerHTML = "Innovation Opportunity";
          highest.innerHTML = "Personal Strength";
          lowest.style.bottom = sameScoreWithBothLabels ? "-25px" : "-12px";
        }
        if(finalText === "HOST") {
          lowest?.classList.add("sixth-text"); 
          highest?.classList.add("sixth-text");
          lowest.style.bottom = sameScoreWithBothLabels ? "-28px" : "-28px";
          highest.style.bottom = sameScoreWithBothLabels ? "20px" : "20px";
        }
        if(finalText === "LEARNER") {
          lowest?.classList.add("seventh-text"); 
          highest?.classList.add("seventh-text");
          lowest.style.bottom = sameScoreWithBothLabels ? "20px" : "20px";
          highest.style.bottom = sameScoreWithBothLabels ? "-26px" : "-26px";
        }
      }
      if(windowWidth < 551) {
        if (finalText === "CULTURE MAKER" || finalText === "BEGINNER") {
          // labelsArr[i].getElementsByClassName("lowest-score")[0].style.bottom = "-32px";
          // labelsArr[i].getElementsByClassName("highest-score")[0].style.bottom = "34px";
        }
      } 
    }

  }, [chartOptions, sortedLabelsAndInfo, closestHighScores, closestHighScores?.id, highestClosestSortedScores, hoveredScoreObj, sortedAccToScores, sameScoreWithBothLabels, windowWidth]);

  // const quotientVal = 3;
  const showTitleText = () => {
    return (<h1>{name}, you should start with the <span className="lowest-score-name">{closestHighScores?.id?.toLowerCase()}'s</span> Perspective</h1>);
  };

  // const showScoreText = () => {
  //   if (score > 95) {
  //     return <div className="result-text">

  //       <p>Congratulations! It looks like your church is displaying clear signs of moving toward great health.</p>
  //       <p>We estimate that less than 1 percent of churches will score this highly, So, we encourage your church to continue what it is presently doing to ensure it stays effective and healthy.</p>
  //     </div>
  //   } else if (score > 80) {
  //     return <div className="result-text">

  //       <p>It's great that you are already working on key issues but your church can surely do more and get healthier.</p>
  //       <p>Your church has significant potential to move to the healthiest level. So, we encourage your leadership to look for potential areas of growth and see what remedies are possible to ensure your church becomes even healthier.</p>
  //     </div>
  //   } else if (score > 70) {
  //     return <div className="result-text">

  //       <p>Based on your current score, it looks like your church needs to work on becoming healthier.</p>
  //       <p>Often, churches at this level might need significant work to move into the healthy category and I hope this assessment has revealed some clear starting points.</p>
  //       <p>We encourage your church to give special attention to the areas you scored lower in, so you can begin to grow toward greater church health.</p>
  //     </div>
  //   } else {
  //     return <div className="result-text">
 
  //       <p>Based on your current score, it looks like your church is currently battling unhealthiness.</p>
  //       <p>While the task might seem hard and progress might appear minimal, please remember that significant growth or a meaningful ministry might be just around the corner.</p>
  //       <p>By focusing and working on all the critical areas revealed in this assessment, you can easily begin the journey to improving your church's health!</p>
  //     </div>
  //   }
  // };

  const showProgressBarText = (scoreValue) => {
    if (scoreValue >= 95) {
      return "Enjoying health";
    } else if (scoreValue >= 80 && scoreValue < 95) {
      return "Improving health";
    } else if (scoreValue >= 70 && scoreValue < 80) {
      return "Pursuing health";
    } else if (scoreValue < 70) {
      return "Battling unhealthiness";
    }
  }

  const showInvite = () => {
    if (isMember) {
      return null;
    } else {
      return <div className="invite-container">

        <h3>Invite your team to take this test</h3>
        <p>Everyone has a unique perspective, even with the same team. Find out how others see innovation and view your ministry's strengths and needs. Invite them to take the quiz and start a conversation around the results.</p>

        {/* <div className="invite-reasons">

          <div className="reason">
            <img src={analyzeIcon} alt="analyze" /><br />
            <div className="height30"></div>
            <span>Analyze</span><br />
            Discover what level of health your church is currently at
          </div>

          <div className="reason">
            <img src={alignmentIcon} alt="align" /><br />
            <div className="height30"></div>
            <span>Align</span><br />
            Align around a plan for increased effectiveness as a team
          </div>

          <div className="reason">
            <img src={advanceIcon} alt="advance" /><br />
            <div className="height30"></div>
            <span>Advance</span><br />
            Chart your course to move toward a greater level of church health
          </div>
        </div> */}

        <div className="cta-btn">
          <Link to={"/invite-team/" + id} target="_blank">
            <Button>INVITE YOUR TEAM</Button>
          </Link>
        </div>

      </div>
    }
  };

  const showTeamMember = () => {
    if (isMember) {
      return (
        <div className="team-container">
          <h3>Thank you for taking the test!</h3>
          Please get in touch with your leader for the next step.
          <br />
          <br />
        </div>
      );
    } else {
      return null;
    }
  };

  const getLeftMargin = () => {
    if (window.innerWidth < 800 && score > 95) {
      return { left: 90 + "%" };
    } else if (window.innerWidth < 800 && score < 5) {
      return { left: 10 + "%" };
    } else {
      return { left: score + "%" };
    }
  };

  const getBarTextLeft = (score) => {
    if (window.innerWidth < 650 && score > 80) {
      return { left: "-170px" }
    }
    return { left: "-100px" }
  }

  const showSchedule = () => {
    if (isMember) {
      return null;
    } else {
      return (
        <div className="schedule-container">
          Schedule a call with Carey to debrief the results of your test and
          ensure your church is prepared with next steps for future evangelism.
          <div className="cta-btn">
            <a
              href="https://calendly.com/kurianbk/hear-the-bleat-story"
              target="_blank"
            >
              <Button>TALK WITH CAREY</Button>
            </a>
          </div>
        </div>
      );
    }
  };

  const showLegacyInvite = () => {
    if (isMember) {
      return null;
    } else {
      return (
        <div className="invite-legacy-container">
          <div className="invite-legacy">
            <p>Invite other church leaders to take this test and help them discover their unique innovation quotient!</p>
            <div className="cta-btn">
              <Link to={"/invite-team/" + id} target="_blank">
                <Button>INVITE YOUR TEAM</Button>
              </Link>
            </div>
          </div>
        </div>
      );
    }
  };

  if (isLoading) {
    return (
      <div className="container results loading">
        <CircularProgress style={{ color: "#00A04F" }} size={60} />
      </div>
    );
  }

  // console.log(labelsAndInfo);
  const scoreText = () => {
    // let score = labelsAndInfo?.find(label => label.id === hoveredText)?.score;
    // let finalScore = Math.round(((score + 50)/100) * 100);
    // return finalScore;
    return calcPercentFromAvg(hoveredScoreObj?.score);
  }

  const avgText = () => {
    // let score = labelsAndInfo?.find(label => label.id === hoveredText)?.avgScore;
    // let finalScore = Math.round(((score + 50)/100) * 100);
    // return finalScore;
    return calcPercentFromAvg(hoveredScoreObj?.avgScore);
  }

  const calcPercentFromAvg = (avg) => {
    // these average scores are all out of 5 so 
    return Math.round((avg / 5) * 100);
  }

  return (
    <>
      <div className="container results">
        <div className="details-container">

          {showTitleText()}
          {/* <span className="big-innovation-quotient">{quotientVal}</span> */}
          {/* <div className="subheader-text">
            {sortedLabelsAndInfo[0].support.map((text, index) => <p key={index} className="support-text">{text}</p>)}
            <p className="improve-text"><span>Steps for improvement: </span>{sortedLabelsAndInfo[0].improve}</p>
          </div>
          
          
          
          <h2 className="results-subheader radar-graph">RADAR GRAPH</h2> */}
          <p className="radar-info">Click on the categories below to see a description of each.</p>
          <div className="spiderweb-chart" id="spiderweb-chart1">
            <HighchartsReact highcharts={Highcharts} options={chartOptions} />
            <div className="label-info" style={infoPosition}>
              <p className="label-text" style={{ marginBottom: infoText ? "10px" : 0 }}>
              {infoText}
              </p>
              <div className="label-score">
                <div className="score-container">
                  <div className="your-score">&nbsp;</div>Personal Strength&nbsp;&nbsp;<span className="score-text">{scoreText()}%</span>
                </div>
              <div className="score-container">
                <div className="average-score">&nbsp;</div>Ministry Need&nbsp;&nbsp;<span className="score-text">{avgText()}%</span>
              </div>
              </div>
            </div>
          </div>

          {/* <div id="chart">
            <ReactApexChart options={apexChartData.options} series={apexChartData.series} type="radar" height={350} />
          </div> */}

          {/* <div className="show-scores">
            <div className="line1"><div className="color1"></div><p>PERSONAL STRENGTH: {calcPercentFromAvg(hoveredScoreObj?.score)}%</p></div> 
            <div className="line2"><div className="color2"></div><p>MINISTRY NEED: {calcPercentFromAvg(hoveredScoreObj?.avgScore)}%</p></div>
          </div> */}
          
          <div className="show-scores-text">
            <div className="line1">
              <p className="line1-header">{closestHighScores?.id?.toLowerCase()}:</p>
              {closestHighScores?.support?.map((text, idx) => <p key={idx}>{text}</p>)}
            </div> 
            <div className="line2">
              {closestHighScores?.improve?.map((text, idx, arr) => {
                console.log("arr", arr);
                if (idx === 0) return <p key={idx}><span className="line2-header">Innovation Opportunity:</span> {text}</p>
                else return <p key={idx}>{text}</p>
              })}
            </div>
          </div>

          <h2 className="results-subheader bar-graph">RESULTS</h2>
          
          
          
          
          {/* <div className="spacer"></div>
          <div className="spacer"></div> */}

          {/* <div className="score-section">
              <div className="score-txt">{subScore2}%</div>
              <div className="score-slider">
                <LinearProgress
                  className="custom-bar"
                  variant="determinate"
                  value={subScore2}
                />
              </div>
              LEADERS
            </div>

            <div className="score-section">
              <div className="score-txt">{subScore3}%</div>
              <div className="score-slider">
                <LinearProgress
                  className="custom-bar"
                  variant="determinate"
                  value={subScore3}
                />
              </div>
              STAFF
            </div> */}

          {/* {showScoreText()} */}
            {/* </div>
      </div> */}

          <div className="score-section-container">

            {/* <div className="score-markers score-markers-mobile">
              <div className="your-score">&nbsp;</div>YOUR SCORE&nbsp;&nbsp;<br/>
              <div className="average-score">&nbsp;</div>AVERAGE SCORE
            </div> */}

            <div className="score-section first-section">
              <div className="line1"><div className="color1"></div><p>PERSONAL STRENGTH</p></div> 
              <div className="line2"><div className="color2"></div><p>MINISTRY NEED</p></div>
            </div>

            <div className="score-section">
              <p className="score-name">KINGDOM BUILDER</p>
              
              <div className="score-slider"><LinearProgress className="custom-bar" variant="determinate" value={calcPercentFromAvg(kingdomBuilder1?.average)} /><span className="score-txt">{calcPercentFromAvg(kingdomBuilder1?.average)}%</span></div>
              <div className="score-slider"><LinearProgress className="custom-bar" variant="determinate" value={calcPercentFromAvg(kingdomBuilder2?.average)} /><span className="score-txt">{calcPercentFromAvg(kingdomBuilder2?.average)}%</span></div>
            </div>

            <div className="score-section">
              <p className="score-name">STORYTELLER</p>
              
              <div className="score-slider"><LinearProgress className="custom-bar" variant="determinate" value={calcPercentFromAvg(storyTeller1?.average)} /><span className="score-txt">{calcPercentFromAvg(storyTeller1?.average)}%</span></div>
              <div className="score-slider"><LinearProgress className="custom-bar" variant="determinate" value={calcPercentFromAvg(storyTeller2?.average)} /><span className="score-txt">{calcPercentFromAvg(storyTeller2?.average)}%</span></div>
            </div>

            <div className="score-section">
              <p className="score-name">PHILOSOPHER</p>
              
              <div className="score-slider"><LinearProgress className="custom-bar" variant="determinate" value={calcPercentFromAvg(philosopher1?.average)} /><span className="score-txt">{calcPercentFromAvg(philosopher1?.average)}%</span></div>
              <div className="score-slider"><LinearProgress className="custom-bar" variant="determinate" value={calcPercentFromAvg(philosopher2?.average)} /><span className="score-txt">{calcPercentFromAvg(philosopher2?.average)}%</span></div>
            </div>

            <div className="score-section">
              <p className="score-name">CULTURE MAKER</p>
              
              <div className="score-slider"><LinearProgress className="custom-bar" variant="determinate" value={calcPercentFromAvg(cultureMaker1?.average)} /><span className="score-txt">{calcPercentFromAvg(cultureMaker1?.average)}%</span></div>
              <div className="score-slider"><LinearProgress className="custom-bar" variant="determinate" value={calcPercentFromAvg(cultureMaker2?.average)} /><span className="score-txt">{calcPercentFromAvg(cultureMaker2?.average)}%</span></div>
            </div>

            <div className="score-section">
              <p className="score-name">BEGINNER</p>
              
              <div className="score-slider"><LinearProgress className="custom-bar" variant="determinate" value={calcPercentFromAvg(beginner1?.average)} /><span className="score-txt">{calcPercentFromAvg(beginner1?.average)}%</span></div>
              <div className="score-slider"><LinearProgress className="custom-bar" variant="determinate" value={calcPercentFromAvg(beginner2?.average)} /><span className="score-txt">{calcPercentFromAvg(beginner2?.average)}%</span></div>
            </div>

            <div className="score-section">
              <p className="score-name">HOST</p>
              
              <div className="score-slider"><LinearProgress className="custom-bar" variant="determinate" value={calcPercentFromAvg(host1?.average)} /><span className="score-txt">{calcPercentFromAvg(host1?.average)}%</span></div>
              <div className="score-slider"><LinearProgress className="custom-bar" variant="determinate" value={calcPercentFromAvg(host2?.average)} /><span className="score-txt">{calcPercentFromAvg(host2?.average)}%</span></div>
            </div>
            
            <div className="score-section">
              <p className="score-name">LEARNER</p>
              
              <div className="score-slider"><LinearProgress className="custom-bar" variant="determinate" value={calcPercentFromAvg(learner1?.average)} /><span className="score-txt">{calcPercentFromAvg(learner1?.average)}%</span></div>
              <div className="score-slider"><LinearProgress className="custom-bar" variant="determinate" value={calcPercentFromAvg(learner2?.average)} /><span className="score-txt">{calcPercentFromAvg(learner2?.average)}%</span></div>
            </div>
            
            <div className="score-section last-section">
              
              <div className="line1"><div className="color1"></div><p>PERSONAL STRENGTH</p></div> 
              <div className="line2"><div className="color2"></div><p>MINISTRY NEED</p></div>
              
            </div>

            {/* <div className="score-markers score-markers-desktop">
              <div className="your-score">&nbsp;</div>YOUR SCORE&nbsp;&nbsp;
              <div className="average-score">&nbsp;</div>AVERAGE SCORE
            </div> */}

          </div>

        </div>
      </div>

      {showInvite()}

      {/* {showTeamMember()} */}

      <div className="testimonial-container">

        <div className="testimonial">

          <div className="testi">
            <span className="quotation-mark">“</span>Innovation is less about changing the world and more about changing our perspective so we can participate in how God wants to change the world.<span className="quotation-mark bottom"><div className="quotation-block">“</div></span>
          </div>

          <div className="avatar">
            <img src={testimonialImage} />
          </div>

          <div className="designation">
            <p className="person-name">Liam Savage</p>
            <p className="person-info">Author, <span className="book-name">Green Sky Innovation</span></p>
          </div>

        </div>

      </div>

      {/* {showSchedule()} */}
      {/* {showLegacyInvite()} */}

      {/* </div> */}

      <section className="book-section">
        <div className="book-image">
          <img src={bookImg} alt="The Green Sky Innovation book by Liam Savage" />
        </div>
        <div className="book-details">
          <h3>BUY THE BOOK</h3>
          <p>Get started on the journey toward unlocking your creativity and multiplying Kingdom effectiveness!</p>
          <p className="available-now">Available now:</p>
          <div className="book-links">
            
            <a className="book-link" rel="noreferrer" target="_blank" href="https://www.amazon.com/Green-Sky-Innovation-Future-Ministry/dp/B0BNMCZ49Z?_encoding=UTF8&qid=1670036997&sr=8-1&linkCode=sl1&tag=greensky0e6-20&linkId=dc7c68a23d05f92a5b7ff545f5da1d17&language=en_US&ref_=as_li_ss_tl" 
            title="Green Sky Innovation book by Liam Savage - amazon.com">
              <img className="shop-logo ebooks" src={amazon} alt="Green Sky Innovation book by Liam Savage on amazon.com" /></a>
            
            <a className="book-link" rel="noreferrer" target="_blank" href="https://www.audible.com/pd/B0BS4V5312/?source_code=AUDFPWS0223189MWT-BK-ACX0-336452&ref=acx_bty_BK_ACX0_336452_rh_us" 
            title="Green Sky Innovation audio book by Liam Savage - audible.com">
              <img className="shop-logo ebooks" src={audible} alt="Green Sky Innovation audio book by Liam Savage on audible.com" /></a>

            <a className="book-link" rel="noreferrer" target="_blank" href="https://books.apple.com/us/book/green-sky-innovation/id6444779160" 
            title="Green Sky Innovation audio book by Liam Savage - books.apple.com">
              <img className="shop-logo apple" src={apple} alt="Green Sky Innovation audio book by Liam Savage on books.apple.com" />
              <img className="shop-logo books" src={books} alt="Green Sky Innovation audio book by Liam Savage on books.apple.com" /></a>
            
            {/* <a className="book-link" rel="noreferrer" target="_blank" href="https://www.lifeway.com/en/product/the-innovation-crisis-P008015722" 
            title="Green Sky Innovation book by Liam Savage - lifeway.com">
              <img className="shop-logo" src={lifeway} alt="Green Sky Innovation book by Liam Savage on Lifeway" /></a> */}
            
            <a className="book-link" rel="noreferrer" target="_blank" href="https://www.barnesandnoble.com/w/green-sky-innovation-liam-savage/1142771518?ean=9798218035822" 
            title="Green Sky Innovation book by Liam Savage - barnesandnoble.com">
              <img className="shop-logo ebooks" src={barnesNoble} alt="Green Sky Innovation book by Liam Savage on barnes & noble" /></a>
            
            {/* <a className="book-link" rel="noreferrer" target="_blank" href="https://www.moodypublishers.com/the-innovation-crisis/" 
            title="Green Sky Innovation book by Liam Savage - moodypublishers.com">
              <img className="shop-logo" src={moody} alt="Green Sky Innovation book by Liam Savage on moody.com" /></a> */}
            
            {/* <a className="book-link" rel="noreferrer" target="_blank" href="https://www.target.com/p/the-innovation-crisis-by-ted-esler-paperback/-/A-84117799" 
            title="Green Sky Innovation book by Liam Savage - target.com">
              <img className="shop-logo" src={target} alt="Green Sky Innovation book by Liam Savage on target.com" /></a> */}
            
            <a className="book-link" rel="noreferrer" target="_blank" href="https://www.ebooks.com/en-us/book/210715035/green-sky-innovation/liam-savage/" 
            title="Green Sky Innovation book by Liam Savage - ebook.com">
              <img className="shop-logo ebooks" src={ebooks} alt="Green Sky Innovation book by Liam Savage on ebook.com" /></a>
              
          </div>
        </div>
      </section>

    </>

  );
};
export default Results;
