import React, { useState } from 'react'
import { Link, useSearchParams } from 'react-router-dom'

const tilesInfoArr = [
  { id: 1, title: "Messaging", content: "Are you communicating effectively with your audiences?" },
  { id: 2, title: "Experience", content: "Do you need to design a more engaging experience for your church?" },
  { id: 3, title: "Metrics", content: "Are you prioritizing the need to measure your ministry and staff metrics? " },
  { id: 4, title: "Data & Leadership", content: "Are you utilizing high-quality data reporting to support your vision and mission?" },
  { id: 5, title: "Data Analysis Abilities", content: "Should leveling up to analyze your church’s data be your #1 focus?" },
  { id: 6, title: "Status Quo", content: "Do you need to redefine your church’s indicators for progress and success?" },
  { id: 7, title: "Data Desire", content: "Is it time to shift your focus to utilizing tools that drive better decision-making?" },
  { id: 8, title: "Data & Source", content: "Do you need to better interpret data to translate this into actionable next steps?" },
  { id: 9, title: "Data Methodology", content: "Do you need to change the way your church collects and analyzes data?" },
]


const HowItWorks = () => {

  const [searchParams, setSearchParams] = useSearchParams();
  const [clickedTileContent, setClickedTileContent] = useState({ title: "", content: "" })
  const [activeTile, setActiveTile] = useState({ active: null })
  const [info, setInfo] = useState(true);
  searchParams.get("leader");

  const assessmentLink = () => {
    if (searchParams == "") {
      return "/assessment";
    } else {
      return "/assessment?" + searchParams;
    }
  }

  // handle tile click to show active tile and related content 
  const handleTileClick = (props) => {
    setClickedTileContent({ title: props?.title, content: props?.content })
    setActiveTile({ active: props?.id })
  }

  const text = (<>{/*<p className="desktop-only">With multiple opportunities for ministry, it can be challenging to identify which area needs to be your priority.</p>
    <p className="info-text">Which of these 9<span className="more-info">*</span> opportunities is your church's top ministry priority?</p>*/}
    <p className="info-text">First, let's discover your inner innovator–because there's one inside all of us.</p>
    <p className="info-text">Answer each question thinking about your natural personality.</p>
    <p className="info-text">Don't spend too long on each question. Go with your first instinct.</p>
    </>);
  
  const defaultText = () => {
    setActiveTile({ active: null })
    setClickedTileContent({ title: "", content: text })
    setInfo(true)
  }

  const showClickedTileInfo = (props) => {
    return (
      props.title ? (
        <>
          <h2>{props?.title}</h2><p>{props?.content}</p>
        </>
      ) : (text)
    )
  }

  return (
    <div className='how-it-works' onMouseLeave={() => defaultText()}>
      {/*<p className="info-text-mobile"><span className="more-info">*</span>Click on each grid to see how the category is framed.</p> 
      <div className='how-it-works-tiles'>
        {tilesInfoArr.map((item) => {
          return (
            <button type='button' key={item.id} className={`how-it-works-btn ${item.id === activeTile.active ? 'active' : ''}`}
              onClick={() => handleTileClick(item)}
              onMouseEnter={ () => {handleTileClick(item); setInfo(false)}}
              // onMouseLeave={() => handleTileClick(item)}
            >{item.title}</button>)
        })}
      </div>*/}
      <div className='how-it-works-explanation'>
        <div className="explanation-container">
        {showClickedTileInfo(clickedTileContent)}
        <Link to={assessmentLink()} className="test-btn">I'M READY</Link>
        </div>
        {/*{info ? <p className="info-text-desktop"><span className="more-info">*</span>Click on each grid to see how the category is framed.</p> : null} */}
      </div>
    </div>
  )
}

export default HowItWorks;
