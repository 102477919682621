import { useState, useEffect } from 'react';
import { LinearProgress } from '@mui/material';
import UserIcon from '../../Assets/Images/users.svg';

const titles = [
  { id: 1, title: "Messaging", subheader: "Are you communicating effectively with your audiences?" },
  { id: 2, title: "Experience", subheader: "Do you need to design a more engaging experience for your church?" },
  { id: 3, title: "Metrics", subheader: "Are you prioritizing the need to measure your ministry and staff metrics? " },
  { id: 4, title: "Data & Leadership", subheader: "Are you utilizing high-quality data reporting to support your vision and mission?" },
  { id: 5, title: "Data Analysis Abilities", subheader: "Should leveling up to analyze your church's data be your #1 focus?" },
  { id: 6, title: "Status Quo", subheader: "Do you need to redefine your church's indicators for progress and success?" },
  { id: 7, title: "Data Desire", subheader: "Is it time to shift your focus to utilizing tools that drive better decision-making?" },
  { id: 8, title: "Data & Source", subheader: "Do you need to better interpret data to translate this into actionable next steps?" },
  { id: 9, title: "Data Methodology", subheader: "Do you need to change the way your church collects and analyzes data?" },
];

const DashboardSectionProgressBars = (props) => {

  // const { subScore1, subScore2, subScore3, subScore4, subScore5, subScore6, subScore7, subScore8, subScore9 } = props?.subscores;
  // console.log(props.subscores);

  // console.log(subScore1, subScore2, subScore3, subScore4, subScore5, subScore6, subScore7, subScore8, subScore9);

  const hasWindow = typeof window !== 'undefined';
  
  const getWindowDimensions = () => {
    const width = hasWindow ? window.innerWidth : null;
    // const height = hasWindow ? window.innerHeight : null;
    return { width };
  }
  
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  let htmlContainsPDFClass = props?.pdfClass;
  
  useEffect(() => {
  
    if (hasWindow) {
      setWindowDimensions(getWindowDimensions());
      function handleResize() {
        setWindowDimensions(getWindowDimensions());
      }

      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }
  }, [hasWindow]);
  
  // console.log(windowDimensions);
  // console.log(props);
  const unsortedSubscores = Object.values(props?.subscores);
  // console.log(unsortedSubscores);

  const unsortedSubscoresWithTitles = titles.map((item, index) => {
    // console.log("log i", index, item);
    return {
      ...item,
      subscore: unsortedSubscores[index]
    }
  });

  // console.log(unsortedSubscoresWithTitles);

  const sortedSubscoresWithTitles = unsortedSubscoresWithTitles.sort((a, b) => b?.subscore - a?.subscore);

  // console.log(sortedSubscoresWithTitles);

  const calcLeft = (subscore) => {
    return subscore === 100 ? (subscore - 61) : subscore < 15 ? (-42) : (subscore - 60);
  }

  // get maximum number of totalUsers for minWidth of member icons (div.user-amount) 
  const getMaxUsers = () => {
    let getTotalUsers = props?.sections?.map(section => typeof section?.totalUsers === "number" ? section?.totalUsers : 0);
    let maxTotalUsers = getTotalUsers?.sort((a, b) => b - a)[0];
    return maxTotalUsers;
  }

  let totalUsers = null;

  const getUsers = (section, num = null) => {
    let currentSection      = props.sections.find(function(obj) { return obj.order_no == section.id; });

    totalUsers              = currentSection?.totalUsers;
    // totalUsers              = 2;
    let users               = [];
    if(currentSection?.totalUsers) {
      let uCount            = 0;
      // for(let u = 0; u < currentSection.totalUsers; u++ ) {
      for(let u = 0; u < totalUsers; u++ ) {
        if (num) {
          users = [<img src={UserIcon} alt="user icon" />]; 
        } else {
          users.push(<img src={UserIcon} alt="user icon" />);
        }
        uCount++;
      }
      users.push(<div className="number">{uCount}</div>);
      return users;
    }
    return null;
  }
  

  return (
    <div className="bar-container">
      <h1>Here's your church score</h1>
      {/* <h1 className="result-title">{sortedSubscoresWithTitles[0]?.title}</h1> */}
      {/* <p className="result-info">{sortedSubscoresWithTitles[0]?.subheader}</p> */}
      <p className="result-info">We're hopeful this report will help you celebrate areas of success and identify areas of opportunity for stronger alignment as a church team!</p>

      <div className="result-sections non-pdf-result-sections">
        {sortedSubscoresWithTitles?.map((item, index) => {
          
          let showUsersOrIcon = null;
          if(htmlContainsPDFClass || windowDimensions.width <= 1100) {
            showUsersOrIcon = getUsers(item, 1);
          } else showUsersOrIcon = getUsers(item);
          // windowDimensions.width <= 1100 ? showUsersOrIcon = getUsers(item, 1) : showUsersOrIcon = getUsers(item);
          
          return (
            <div className={`score-section ${index === 0 ? "first-result-title" : ""}`} key={index}>
              <div className="result-section-title"><p><span>{index + 1}.</span> &nbsp;&nbsp;&nbsp;{item?.title}</p></div>
              <div className="score-progress-bar">

                <LinearProgress variant="determinate" value={item?.subscore} />
                <span className="score-inside-progress-bar" style={{ position: "relative", textAlign: "left", left: `${calcLeft(item?.subscore)}%`, top: "-27px", margin: 0, zIndex: 999999, fontWeight: 700, fontSize: "1rem", color: "white" }}>{item?.subscore}%</span>
                
              </div>
              
              <div className="user-amount" style={ windowDimensions.width <= 1100 ? { minWidth: "70px" } : { minWidth: `${(getMaxUsers() + 1) * 35}px`} }>{ showUsersOrIcon }</div>
              
            </div>
          )
        })}
      </div>


      <div className="result-sections pdf-result-sections">
        {sortedSubscoresWithTitles?.map((item, index) => {
            
          let showUsersOrIcon = null;
          showUsersOrIcon = getUsers(item, 1);
          
          return (
            <div className={`score-section ${index === 0 ? "first-result-title" : ""}`} key={index}>
              <div className="result-section-title"><p><span>{index + 1}.</span> &nbsp;&nbsp;&nbsp;{item?.title}</p></div>
              <div className="score-progress-bar">

                <div className="custom-pdf-bar" value={item?.subscore}>
                  <div className="custom-pdf-bar-progress" 
                    style={{ width: `${item?.subscore}%` }}>

                    <p className="score-inside-progress-bar" 
                      style={{ textAlign: "right", width: `calc(100% - 8px)`, 
                      margin: 0, padding: "0px 0px 0.25rem 0px", zIndex: 999999, fontWeight: 700, fontSize: "0.925rem", color: "white" }}>{item?.subscore}%
                    </p>

                  </div>
                </div>
                
              </div>
              
              <div className="user-amount" style={ windowDimensions.width <= 1100 ? { minWidth: "70px" } : { minWidth: `${(getMaxUsers() + 1) * 35}px`} }>{ showUsersOrIcon }</div>
              
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default DashboardSectionProgressBars;
