import React, { useState, useEffect, useContext } from 'react';
import { useNavigate, useSearchParams, useParams } from "react-router-dom";
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import LinearProgress from '@mui/material/LinearProgress';

import Question from '../Components/Questions';
import ProgressBar from '../Components/ProgressBar';
import ProgressBarSingle from '../Components/ProgressBarSingle';

import prevArrow from '../Assets/Images/prev-arrow.svg';
import nextArrow from '../Assets/Images/next-arrow.svg';
import AssessmentQuestionContext from '../Store/AssessmentQuestionContext';
import useWindowDimensions from '../Hooks/useWindowDimensions';

const Assessment = () => {
  
  const { windowWidth }   = useWindowDimensions();
  const assessCtx         = useContext(AssessmentQuestionContext);
  let navigate            = useNavigate();
  const { id }            = useParams();

  useEffect(() => {
    // document.body.style.backgroundColor = "#FBF9F2";
    
    setIsLoading(true);
    // setQs(questions);
    if(id != null) {      
      getAssessment();      
    } else getQuestions();

    // go to first additional question when you click you're all set button on last assessment question 
    localStorage.setItem("backFromDetailsPage", false);

  }, []);

  const [searchParams, setSearchParams]   = useSearchParams();
  const [isLoading, setIsLoading]         = useState(true);
  const [activeStep, setActiveStep]       = useState(0);
  const [prevValue, setPrevValue]         = useState(0);
  const [nextValue, setNextValue]         = useState(0);
  const [questions, setQuestions]         = useState([]);
  const [hasAdditional, setHasAdditional] = useState(false);
  const [questionCount, setQuestionCount] = useState(0);
  const [currentHash, setCurrentHash]     = useState(0);
  const [qs, setQs]                       = useState([]);
  const [section, setSection]             = useState(1);
  const sectionIdOffset = 81;
  
  // const [nextDisabled, setNextDisabled] = useState(true);

  const backFromHalfway = localStorage.getItem("backFromHalfway");
  const goToNextSection = localStorage.getItem("goToNextSection");
  const backFromHalfwayFunc = () => {
    if(backFromHalfway === "true") {
      setActiveStep(13);
      localStorage.removeItem("backFromHalfway");
    }
  }
  const goToNextSectionFunc = () => {
    if(goToNextSection === "true") {
      setActiveStep(14);
      localStorage.removeItem("goToNextSection");
    }
  }
  
  useEffect(() => {
    backFromHalfwayFunc();
  }, [backFromHalfway])
  
  useEffect(() => {
    goToNextSectionFunc();
  }, [goToNextSection])

  function getAssessment() {
    fetch(process.env.REACT_APP_API_URI + '/v1/assessment/' + id + '/review', {
			method: 'GET',
			mode: 'cors',
			headers: { 'Content-Type': 'application/json' }
		}).then(response => {
			return response.json();
		}).then(data => {
			if(data.status == "success") {
        if(data.sections) {
          assessCtx.setSections(data.sections);
          localStorage.setItem("sections", JSON.stringify(data.sections));
        }
				setQuestions(data.questions);
				setHasAdditional(data.hasAdditional);
        setQuestionCount(data.questions.length);        
        setIsLoading(false);
        if(backFromHalfway) backFromHalfwayFunc();
        if(goToNextSection) goToNextSectionFunc();
        if(!backFromHalfway && !goToNextSection) setActiveStep(data.questions.length - 1);
        setPrevValue(data.questions[data.questions.length - 1].answer);
        setCurrentHash(id);
			} else {
				// setOpenSnackbar(true);
			}
		});
  }

  function getQuestions() {
    if(assessCtx.questions.length > 0) {
      console.log("assessCtx.questions", assessCtx.questions);
      setQuestions(assessCtx.questions);
      setQuestionCount(assessCtx.questions.length);
      setIsLoading(false);

      // go to the last additional question if coming back from Details page 
      // typeof localStorage.getItem("backFromDetailsPage") is string so "true"
      if(localStorage.getItem("backFromDetailsPage") === "true") {
        setActiveStep(assessCtx.questions.length - 1);
      } else {
        // go to the first unanswered question 
        for (let i = 0; i < assessCtx.questions?.length; i++) {
          if(!assessCtx.questions?.[i]?.answer && !assessCtx.questions?.[i]?.answerTxt) {
            setActiveStep(i);
            break;
          } else setActiveStep(assessCtx.questions.length - 1);
        }
      }

    } else {
      let clientId              = process.env.REACT_APP_CLIENT_ID;
      
      fetch(process.env.REACT_APP_API_URI + '/v1/questions/' + clientId, {
        method: 'GET',
        mode: 'cors',
        headers: { 'Content-Type': 'application/json' }
      }).then(response => {
        return response.json();
      }).then(data => {
        if(data.status == "success") {
          let questions = data.questions;
          if(retrieveStoredAnswers()) {
            let answeredQuestions = [];
            let storedQuestions = retrieveStoredAnswers();
            questions.forEach(q => {
              let question = storedQuestions.find(obj => obj.id == q.id);
              if(question) {
                q.answerTxt = question.answerTxt;
                q.answer = question.answer;
                q.answers = question.answers;
              }
            })
          }
          console.log("UUUUUUUUUUUUUUUUU",data);
          assessCtx.setSections(data.sections);
          localStorage.setItem("sections", JSON.stringify(data.sections));
          setQuestions(questions);
          setHasAdditional(data.hasAdditional);
          setQuestionCount(data.questions.length);
          assessCtx.addQuestions(questions);
          setIsLoading(false);
        } else {
          // setOpenSnackbar(true);
        }
      });
    }

  }

  function completeAssessment() {

    setIsLoading(true);

    let apicall               = '/v1/assessment';
    if(searchParams.get("leader") != null) {
      apicall                 = '/v1/assessment/' + searchParams.get("leader") + '/member/' + searchParams.get("user");
    }

    let isReview              = false;
    if(id != null) {      
      isReview                = true;
    }

    let source                = localStorage.getItem("source") || "";
    let clientId              = process.env.REACT_APP_CLIENT_ID;

		fetch(process.env.REACT_APP_API_URI + apicall, {
			method: 'POST',
			mode: 'cors',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify({
        clientId,
				questions,
        isReview,
        id,
        source
			})
		}).then(response => {
			return response.json();
		}).then(data => {
			if(data.status == "success") {
        if(hasAdditional) {
          navigate("/assessment-additional/" + data.id, { replace: true });
        } else {
          if(data.member == true) {
            navigate("/details/" + data.id + '?member=' + data.memberId, { replace: true });
          // if([...searchParams]?.length) {
          //   navigate("/details/" + data.id + '?' + searchParams, { replace: true });
          } else {
            navigate("/details/" + data.id, { replace: true });
          }
        }
			} else {
				// setOpenSnackbar(true);
			}
		});

	}

  const retrieveStoredAnswers = () => {
    if (typeof window !== 'undefined') {
      // Get the current localstorage
      let savedAnswers = JSON.parse(localStorage.getItem('assessmentAnswers'));
      if(savedAnswers && savedAnswers.length != 0) {
        return savedAnswers;
      }
    }
    return;
  }

  const getQuestionsPerSection = () => {
    let arr = questions?.map(q => q.sectionId);
    let count = {};
    arr.forEach(element => {
      count[element] = (count[element] || 0) + 1;
    });
    return Object.values(count);
  }
  
  const questionsPerSection = getQuestionsPerSection();

  const prevQuestion = () => {
    console.log("\n\n\nactiveStep", activeStep);
    if(activeStep < 1) {
      navigate("/start?" + searchParams, { replace: true });
    } else if(activeStep === 14) {
      setActiveStep(13);
      if(currentHash) navigate("/half/" + currentHash + '/?' + searchParams, { replace: true });
      else navigate("/half?" + searchParams, { replace: true });
    } else {
      setActiveStep(activeStep - 1);
      setPrevValue(questions[activeStep - 1].answer);
      setSection(questions[activeStep - 1].sectionId - sectionIdOffset);
    }
  };

  const nextQuestion = () => {
    console.log("\n\n\nactiveStep", activeStep);
    if(activeStep + 1 <= questions.length - 1 && activeStep !== 13) {
      setActiveStep(activeStep + 1);
      setPrevValue(questions[activeStep + 1]?.answer);
      assessCtx.updateAnswer(questions[activeStep].id, questions[activeStep].answer);
      assessCtx.updateTextAnswer(questions[activeStep].id, questions[activeStep].answerTxt);
      setSection(questions[activeStep + 1].sectionId - sectionIdOffset);
    } else if(activeStep === 13) {
        if(currentHash) navigate("/half/" + currentHash + '/?' + searchParams, { replace: true });
        else navigate("/half?" + searchParams, { replace: true });
    } else if(currentHash != 0 && hasAdditional) {
      if(searchParams.get("member") != null) {
        navigate("/assessment-additional/" + currentHash + '?member=' + searchParams.get("member"), { replace: true });
      } else {
        navigate("/assessment-additional/" + currentHash, { replace: true });
      }
    } else if(currentHash !== 0 && !hasAdditional) {
      if(searchParams.get("member") != null) {
        navigate("/details/" + currentHash + '?member=' + searchParams.get("member"), { replace: true });
      } else {
        navigate("/details/" + currentHash + '?' + searchParams, { replace: true });
      }
    }
  };

  const showNextButton = () => {
    if (activeStep === questions.length - 1) {
      return (
        // <Button className="all-set-btn" onClick={nextQuestion} style={nextDisabled()}>
        <Button className="all-set-btn" onClick={() => completeAssessment()} style={nextDisabled()}>
          {/* You're All Set! */}
          CONTINUE
        </Button>);
    } else return (
      <Button onClick={nextQuestion} style={nextDisabled()}>
        &nbsp;&nbsp;<img src={nextArrow} />
      </Button>);
  }

  const moveToNextQuestion = (answer) => {
    if(activeStep < questions.length - 1) {
      questions[activeStep].answer = answer;
      assessCtx.updateAnswer(questions[activeStep].id, questions[activeStep].answer);
      assessCtx.updateTextAnswer(questions[activeStep].id, questions[activeStep].answerTxt);
      setTimeout(() => {
        setActiveStep(activeStep + 1);
        setPrevValue(questions[activeStep]?.answer);
        setSection(questions[activeStep + 1].sectionId - sectionIdOffset);}, 500);
      setTimeout(() => {
        if(activeStep == 13) {
          if(currentHash) navigate("/half/" + currentHash + '/?' + searchParams, { replace: true });
          else navigate("/half?" + searchParams, { replace: true });
        }
      }, 0);
      
      console.log(activeStep)
    } else {
      questions[activeStep].answer = answer;
      setTimeout(() => { completeAssessment(); }, 500);
    }
  }

  // const nextDisabled = () => {
  //   if(questions[activeStep].answer > 0) {
  //     return {display: "block"};
  //   };
  //   return {display: "none"};
  // }
  const nextDisabled = (answer) => {
    if(questions[activeStep].mandatory == "no") {
      return {display: "block"};
    } else {
      if(questions[activeStep].type == "slider" && questions[activeStep].answer != 0) {
        return {display: "block"};
      } else if(questions[activeStep].type == "radio" && questions[activeStep].answer != 0) {
        return {display: "block"};
      } else if(questions[activeStep].type == "textarea" && questions[activeStep].answerTxt != "") {
        return {display: "block"};
      }
    } 
    return {display: "none"};
  }

  const getStepContent = (step) => {
    return (
      <Question question={questions[step]} questionCount={questionCount} questionAnswered={moveToNextQuestion} currentValue={questions[activeStep].answer}/>
    );
  };

  if (isLoading) {
		return <div className="container assessment loading"><CircularProgress style={{ color: '#00A04F' }} size={60} /></div>
	}

  return (
    <div className="container assessment">
      <Box sx={{ width: '100%' }}>
        <Stepper activeStep={activeStep}></Stepper>
      </Box>
      <div>
        <div className="steps-container">
          <div>
            <div className="steps">

              <div className="spacer"></div>
              <ProgressBar currentProgress={activeStep} questionsPerSection={questionsPerSection} sections={assessCtx.sections} count={questionCount}/>
              {/*<ProgressBarSingle currentProgress={activeStep} totalNumOfQuestions={questionCount} section={section} />*/}
                <div className="progress-indicator"><strong>{activeStep + 1}</strong>/{questions.length}</div>
                {getStepContent(activeStep)}
                <div className="step-buttons" style={{ margin: questions[activeStep]?.type === "radio" ? "45px auto 30px" : windowWidth < 651 ? "105px auto 30px" : "130px auto 30px", padding: questions[activeStep]?.type === "radio" ? "0 0 45px" : "0 0 30px" }}>
                  <Button onClick={prevQuestion}>
                    <img src={prevArrow} />&nbsp;&nbsp;
                  </Button>
                  {showNextButton()}
                </div>
              
            </div>
          </div>
        </div>
      </div>
      
    </div>
  );
}
export default Assessment;