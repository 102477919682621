import React from 'react';
import { Fragment } from 'react';
// import BleatLogo from '../../Assets/Images/logo-bleat.svg';
import BleatLogo from '../../Assets/Images/logo-bleat-new.png';
import BleatLogoDark from '../../Assets/Images/bleat-logo-dark.png';
import ChurchFuelLogo from '../../Assets/Images/logo-churchfuel.png';

import useWindowDimensions from '../../Hooks/useWindowDimensions';

const Footer = props => {

  const { windowWidth } = useWindowDimensions();

  return <Fragment>
    <footer>
      {/*<div className="footer-logos-div">
        <div className="footer-logo-left">
          <img src={ChurchFuelLogo} alt="Church Fuel Logo" />
        </div>
        <div className="footer-logo-right">
          <img src={BleatLogo} alt="Bleat Logo" />
        </div>
      </div>*/}
      { windowWidth > 830 ? null:
        <div className='footer-logos-div'>
          <div className='footer-logo-center'>
          <img src={BleatLogoDark} alt='Bleat Logo' />
          </div>
        </div>
      }
    </footer>
  </Fragment>
}

export default Footer;
