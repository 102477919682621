import { useEffect, useState } from "react"
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import LinearProgress from '@mui/material/LinearProgress';
import prevBtnImg from "../../Assets/Images/prev.png";
import nextBtnImg from "../../Assets/Images/next.png";

const titles = [
  { id: 1, title: "Messaging", subheader: "Are you communicating effectively with your audiences?" },
  { id: 2, title: "Experience", subheader: "Do you need to design a more engaging experience for your church?" },
  { id: 3, title: "Metrics", subheader: "Are you prioritizing the need to measure your ministry and staff metrics? " },
  { id: 4, title: "Data & Leadership", subheader: "Are you utilizing high-quality data reporting to support your vision and mission?" },
  { id: 5, title: "Data Analysis Abilities", subheader: "Should leveling up to analyze your church's data be your #1 focus?" },
  { id: 6, title: "Status Quo", subheader: "Do you need to redefine your church's indicators for progress and success?" },
  { id: 7, title: "Data Desire", subheader: "Is it time to shift your focus to utilizing tools that drive better decision-making?" },
  { id: 8, title: "Data & Source", subheader: "Do you need to better interpret data to translate this into actionable next steps?" },
  { id: 9, title: "Data Methodology", subheader: "Do you need to change the way your church collects and analyzes data?" },
];

const DashboardIndividual = ({ dashboardIndividualProps }) => {

  const { selected, updateIndividualAssessment, members, memberScore, sections, memScores, memberSection1, memberSection2, memberSection3, memberSection4, toggleSection, toggleSectionDetails, questions } = dashboardIndividualProps;

  const [toggleState, setToggleState] 					= useState(1);
  const [currentTabIdx, setCurrentTabIdx]				= useState(0);

  useEffect(() => {
		setToggleState(currentTabIdx + 1);
	}, [currentTabIdx]);

  const toggleSectionDisplay = (id) => {
    if (id === toggleSection) {
      return { display: "block" }
    } else {
      return { display: "none" }
    }
  }

  const toggleSectionIcon = (id) => {
    if (id === toggleSection) {
      return <RemoveIcon />
    } else {
      return <AddIcon />
    }
  }

  const showAnswerText = (answer, questionId) => {

    let question = questions?.find(function (obj) { return obj?.id === questionId; });
    let answerType = "green";

    if (question?.answers?.length === 2) {
      if (answer === 1) { answerType = "red"; }
      else if (answer === 2) { answerType = "green"; }
    } else if (question?.answers?.length === 3) {
      if (answer === 1) { answerType = "red"; }
      else if (answer === 2) { answerType = "orange"; }
      else if (answer === 3) { answerType = "green"; }
    } else if (question?.answers?.length === 4) {
      if (answer === 1) { answerType = "red"; }
      else if (answer === 2) { answerType = "red"; }
      else if (answer === 3) { answerType = "green"; }
      else if (answer === 4) { answerType = "green"; }
    } else if (question?.answers?.length === 5) {
      if (answer === 1) { answerType = "red"; }
      else if (answer === 2) { answerType = "red"; }
      else if (answer === 3) { answerType = "orange"; }
      else if (answer === 4) { answerType = "green"; }
      else if (answer === 5) { answerType = "green"; }
    }
    let answerVal = question?.answers?.find(function (obj) { return obj.value === answer; });

    if (answerVal && answerVal.desktop_text) {
      if (answerType === "red") {
        return <div className="answer false">{answerVal.desktop_text.replace(/<br\/\s*\\?>/g, "\r\n")}</div>
      } else if (answerType === "orange") {
        return <div className="answer neutral">{answerVal.desktop_text.replace(/<br\/\s*\\?>/g, "\r\n")}</div>
      } else if (answerType === "green") {
        return <div className="answer true">{answerVal.desktop_text.replace(/<br\/\s*\\?>/g, "\r\n")}</div>
      }
    }

  }

  const calcLeft = (subscore) => {
    return subscore === 100 ? (subscore - 61) : subscore < 15 ? (-42) : (subscore - 60);
  }

  const nextTab = () => {
		let sectionsLen = sections?.length;
		if(currentTabIdx < sectionsLen - 1) {	
			setCurrentTabIdx((prev) => prev + 1);
		} else setCurrentTabIdx(0);
	}
	
	const prevTab = () => {
		let sectionsLen = sections?.length;
		if(currentTabIdx > 0) {	
			setCurrentTabIdx((prev) => prev - 1);
		} else setCurrentTabIdx(sectionsLen - 1);
	}
  
  const selectedMember = members?.find(m => m.id === selected);
  const selectedMemberSubScores = [ selectedMember?.subScore1, selectedMember?.subScore2, selectedMember?.subScore3, selectedMember?.subScore4, selectedMember?.subScore5, selectedMember?.subScore6, selectedMember?.subScore7, selectedMember?.subScore8, selectedMember?.subScore9 ];
  
  console.log("selected", selected); console.log("selectedMember", selectedMember);

  const unsortedSubscores = selectedMemberSubScores;
  const unsortedSubscoresWithTitles = titles.map((item, index) => {
    return { ...item, subscore: unsortedSubscores[index] }
  });
  
  const sortedSubscoresWithTitles = unsortedSubscoresWithTitles.sort((a, b) => b?.subscore - a?.subscore);
  // console.log("unsortedSubscoresWithTitles", unsortedSubscoresWithTitles);
  console.log("sortedSubscoresWithTitles", sortedSubscoresWithTitles);
  console.log("members", members);
  console.log("questions", questions);
  console.log("sections", sections);

  const answerNumToText = (num) => {
    let selectedOption = "";
    switch (num) {
      case 1: selectedOption = "Strongly Disagree"; break;
      case 2: selectedOption = "Disagree"; break;
      case 3: selectedOption = "Neutral"; break;
      case 4: selectedOption = "Agree"; break;
      case 5: selectedOption = "Strongly Agree"; break;
      default: selectedOption = "Answer Unavailable"; break;
    }
    return selectedOption;
  }

  let selectedMemberData = sections?.map(section => ({ sectionName: section.section, sectionId: section.id }))
  selectedMemberData?.forEach(section => {
    let sectionQs = questions?.filter(q => q.sectionId === section.sectionId);
    section.questions = sectionQs?.map(sQ => ({ question: sQ.question, questionId: sQ.id }))
  })
  
  const selectedMemberAnswers = selectedMember?.answers;

  selectedMemberData?.forEach(section => {
    section.questions?.forEach(sectQ => {
      let ans = selectedMemberAnswers?.find((answer) => answer.question_id === sectQ.questionId);
      sectQ.answerTxt = answerNumToText(ans.answer);
      sectQ.answerNum = ans.answer;
    })
  })

  const answerTextColors = (num) => {
    switch (num) {
      case 1: return "answer-text red";
      case 2: return "answer-text red";
      case 3: return "answer-text yellow";
      case 4: return "answer-text green";
      case 5: return "answer-text green";
      default: return "answer-text";
    }
  }

  
  console.log("selectedMemberData", selectedMemberData);

  return (
    <div className="container results">

      <div style={{ height: '20px', marginTop: "45px" }}></div>
      <div className="selection-form">
        <div className="form-detail">
          <TextField select hiddenLabel value={selected} onChange={(e) => { updateIndividualAssessment(e) }} fullWidth variant="standard" size="normal" InputProps={{ disableUnderline: true }} className="form-details-text">
            {members?.map((option) => (
              <MenuItem key={option.id} value={option?.id}>
                {option?.fullName}'s
              </MenuItem>
            ))}
          </TextField>
        </div>
        <div className="form-text">assessment score</div>
      </div>

      <div className="details-container">

        {/* <div className="progress-bar">
          <div className="si_ass_result">
            <div className="si_ass_progress">
              <div className="progress-line" style={{ left: memberScore + '%' }}></div>
              <span className="msi_score" style={{ left: memberScore + '%' }}>{memberScore}%</span>
            </div>
            <div className="pervalue">
              <h4 className="nimp" style={{ minWidth: '45%' }}></h4>
              <h4 className="mexpe" style={{ minWidth: '25.5%' }}></h4>
              <h4 className="eexpe" style={{ minWidth: '29%' }}></h4>
            </div>
            <div className="si_pro_header">
              <h4 className="nimp" style={{ minWidth: '45%' }}>Needs<br />improvement</h4>
              <h4 className="mexpe" style={{ minWidth: '25.5%' }}>Meets<br />expectations</h4>
              <h4 className="eexpe" style={{ minWidth: '29%' }}>Exceeds<br />expectations</h4>
            </div>
          </div>
        </div> */}

        <p className="result-intro">We're hopeful this report will help you celebrate areas of success and identify areas of opportunity for stronger alignment as a church team!</p>
        <div className="result-sections">

          <div className="individual-scores-container">

          {sortedSubscoresWithTitles?.map((item, index) => {
            return (
              <div className={`score-section ${index === 0 ? "first-result-title" : ""}`} key={index}>
                <div className="result-section-title"><p><span>{index + 1}.</span> &nbsp;&nbsp;&nbsp;{item?.title}</p></div>
                <div className="score-progress-bar">

                  <LinearProgress variant="determinate" value={item?.subscore} />
                  <span style={{ position: "relative", textAlign: "left", left: `${calcLeft(item?.subscore)}%`, top: "-27px", margin: 0, zIndex: 999999, fontWeight: 700, fontSize: "1rem", color: "white" }}>{item?.subscore}%</span>

                </div>
              </div>
            )
          })}
          </div>

      <h2 style={{ textAlign: "center", marginTop: "75px" }}>INDIVIDUAL SCORES</h2>
      <div className="selected-member-data">
        <div className="tab-change">
          <p>{currentTabIdx + 1}/{sections?.length}</p>
          <div className="tab-btn-container">
            <button style={{ background: "linear-gradient(180deg, #3884C6 0%, #5DB256 100%)", padding: "12px 16px 12px 14px" }} onClick={() => prevTab()}><img src={prevBtnImg} alt="previous button" /></button>
            <span className="section-name">{sections[currentTabIdx]?.section}</span>	
            <button style={{ background: "linear-gradient(180deg, #3884C6 0%, #5DB256 100%)", padding: "12px 14px 12px 16px" }} onClick={() => nextTab()}><img src={nextBtnImg} alt="next button" /></button>
          </div>
        </div>

        {selectedMemberData[currentTabIdx]?.questions?.map((q, idx) => {
          return <div className={(idx % 2 === 0) ? "even question-answer" : "odd question-answer"}>
            <p className="question">{q.question}</p>
             <p className={answerTextColors(q.answerNum)}>{q.answerTxt}</p>
          </div>
        })}
      </div>

        </div>

        {/* {
          sections.map((data, index) => {

            let memberSection = [];
            if (index === 0) {
              memberSection1.forEach((member) => {
                // console.log(member);
                memberSection.push({ id: member.id, question: member.question, answer: member.answer });
              });
            } else if (index === 1) {
              memberSection2.forEach((member) => {
                memberSection.push({ id: member.id, question: member.question, answer: member.answer });
              });
            } else if (index === 2) {
              memberSection3.forEach((member) => {
                memberSection.push({ id: member.id, question: member.question, answer: member.answer });
              });
            } else if (index === 3) {
              memberSection4.forEach((member) => {
                memberSection.push({ id: member.id, question: member.question, answer: member.answer });
              });
            }
            // console.log(memberSection);

            return <div className="members-answered">
              <p className="section">
                {data.section}
                <a className="section-toggle" onClick={() => toggleSectionDetails(index + 1)}>
                  {toggleSectionIcon(index + 1)}
                </a>
              </p>
              <div style={toggleSectionDisplay(index + 1)}>
                {
                  memberSection.map((data, index) => (
                    <div key={index} className="answered-detail"><p>{data.question}</p> {showAnswerText(data.answer, data.id)}</div>
                  )
                  )}
              </div>
            </div>
          }
          )} */}

        <div style={{ height: "40px" }}></div>

      </div>

    </div>
  )
}

export default DashboardIndividual;
