import React from 'react';
import { Fragment } from 'react';
import { Link } from "react-router-dom";
import useWindowDimensions from '../../Hooks/useWindowDimensions';
// import Logo from '../../Assets/Images/logo.svg';
import Logo from '../../Assets/Images/logo_EL.png';
import Logo_M from '../../Assets/Images/Logo_Mobile.png';
import BleatLogo from '../../Assets/Images/logo_bleat_black_text.png';
// import churchOutreachLogo from '../../Assets/Images/logo-outreach.png';

const Header = props => {

  const { windowWidth } = useWindowDimensions();
  
  return <Fragment>
    <header>
      {/* <Link to="/start" className="logo"><img src={Logo} /></Link> */}
      <div className="assessment-title" id="assessment-title">
        { windowWidth > 830 ? <img src={Logo} alt="churchfuel" className='leadership'/> : <img src={Logo_M} alt="churchfuel" className='mob_lead' />}
        { windowWidth > 650 ? <p>Innovation Quiz</p> : <p>Innovation Quiz</p>}
        { windowWidth > 830 ? <img src={BleatLogo} alt="Bleat Logo" /> : null}
      </div>
    </header>
  </Fragment>
}

export default Header;
